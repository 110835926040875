
<div class="container-fluid c-container-50 btn-login-prices">
  <button *ngIf="!this.userLogging && ((this.cat && this.cat != null) || (this.subcat && this.subcat != null) || (this.sub2cat && this.sub2cat != null))"  style="white-space: normal;background: #FF0000; color: #ffffff; width: 100%;" class="btn btn_register_prices" [routerLink]="['/register'] | localize">{{ 'reseller_login_price' | translate }}</button>
</div>
<div class="c-content-box c-size-xs c-bg-white">
  <div class="container-fluid c-container-50">
    <div class="row">
      <div class="col-xs-12 col-md-6 c-margin-t-10">
        <ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
          <li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>

          <ng-container *ngFor="let breadcrumb of this.breadcrumbs;  "  >
            <li>|</li>
            <li><a [routerLink]=" (breadcrumb.url | localize) " href="javascript:void(0)" >{{ breadcrumb.description  }}</a></li>
          </ng-container>


        </ul>
      </div>
      <div class="col-xs-12 col-md-6 c-margin-t-10">
        <!-- <button *ngIf="!this.userLogging" (click)="this.openmodalloginService.send()" style="white-space: normal;background: #b21f2d; color: #ffffff; " class="custom2-breadcrumbs pull-right btn" >{{ 'isreseller' | translate }}</button> -->
      </div>
    </div>
  </div>
</div>


<div class="container-fluid c-container-50">

  <!--  -->
  <ng-container *ngIf="!this.cat && !this.subcat && !sub2cat && !this.filter.search">
    <div class="Categories text-center">
      <ul style="margin-bottom: 15px;">
        <div class="btn color_head" style="border-radius: 20px; background-color: #e1e6ee; height: 35px; width: 85px; display: inline-block;"  *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
        </div>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="this.cat  && this.cat.length>0 && this.filter.menu != 4">
    <div class="row">
      <div class="col-xs-12">
      <div class="col-xs-1" style="width:20px;padding:0px;top: 24px;">
    <a #cat_div_filter_left href="javascript:void(0)" (click)="divmove(cat_div_filter, 0)">  <img style="height: 20px;margin-right: 5px;" src="assets/images/lefticon.png">  </a>
    </div>
    <div class="col-xs-10" style="width:calc(100% - 70px); padding:0px">
    <div #cat_div_filter class="Categories text-center">
      <ul style="margin-bottom: 15px;"  >
        <a style="border-radius: 20px; border-color: #e1e6ee;" [class]="this.filter.cat==null ? 'btn color_head' : 'btn btn-default  color_black'"  (click)="this.stopScrollF(false);this.filter.cat=null;  this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null; this.calcnewurl('', 'c', 0)" id_input ="cat"  >{{ 'all' | translate }}</a>
        <ng-container *ngFor="let subscat of this.cat;  "  >
          <a style="border-radius: 20px; border-color: #e1e6ee;" [class]="this.filter.cat==subscat.id ? 'btn color_head' : 'btn btn-default color_black'"  id_input ="cat"  (click)="this.stopScrollF(false);this.filter.cat=subscat.id; this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 'c', subscat.id);" >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
        </ng-container>
        <ng-container *ngIf="this.catStock == 1  && this.filter.menu == 1">
          <!-- && userLogging -->
          <a style="border-radius: 20px; background-color: #ff0000; color: #fff;" class="btn btn-default color_black" (click)="this.stopScrollF(false);this.filter.menu=-1; this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null; calcUrlPromotions()">{{ 'stockoff' | translate }}</a>
        </ng-container>
      </ul>
    </div>

  </div>
    <div class="col-xs-1" style="width:20px;  top: 24px;">
    <a #cat_div_filter_right href="javascript:void(0)" (click)="divmove(cat_div_filter, 1)">  <img style="    height: 20px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
    </div>
    </div>
    </div>
    {{ checkElemetScroll(cat_div_filter, cat_div_filter_left, cat_div_filter_right) }}
  </ng-container>


  <ng-container *ngIf="this.subcat  && this.subcat.length>0 && this.filter.menu != 4">
    <div class="row">
      <div class="col-xs-12">
      <div class="col-xs-1" style="width:20px;padding:0px;    top: 24px;">
    <a #subcat_div_filter_left href="javascript:void(0)" (click)="divmove(subcat_div_filter, 0)">  <img style="    height: 20px;margin-right: 5px;" src="assets/images/lefticon.png">  </a>
    </div>
    <div class="col-xs-10" style="width:calc(100% - 70px); padding:0px">
    <div #subcat_div_filter class="Categories text-center">

      <ul style="margin-bottom: 15px;"  >
        <a style="border-radius: 20px; border-color: #e1e6ee;"  [class]=" this.filter.subcat==null ? ' btn color_head  ' : 'btn btn-default  color_black '"  (click)="this.stopScrollF(false);this.filter.subcat=null;   this.sub2cat=null;this.filter.sub2cat=null; this.calcnewurl('', 's', 0)" id_input ="subcat"  >{{ 'all' | translate }}</a>
        <ng-container *ngFor="let subscat of this.subcat;  "  >
          <a style="border-radius: 20px; border-color: #e1e6ee; " [class]=" this.filter.subcat==subscat.id ? ' btn    color_head ' : ' btn btn-default color_black '"  id_input ="subcat"  (click)="this.stopScrollF(false);this.filter.subcat=subscat.id;  this.sub2cat=null;this.filter.sub2cat=null; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 's', subscat.id); " >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
        </ng-container>
        <ng-container *ngIf="this.subcatStock == 1 && this.filter.menu == 1">
          <!-- && this.userLogging -->
          <a style="border-radius: 20px; background-color: #ff0000; color: #fff;" [class]="this.filter.menu==-1 ? 'btn color_head' : 'btn btn-default color_black '" (click)="this.stopScrollF(false);this.filter.menu=-1;this.filter.subcat=null;  this.sub2cat=null;this.filter.sub2cat=null; this.calcUrlPromotions(); ">{{ 'stockoff' | translate }}</a>
        </ng-container>
      </ul>

    </div>
  </div>
    <div class="col-xs-1" style="width:20px;  top: 24px;">
    <a #subcat_div_filter_right href="javascript:void(0)" (click)="divmove(subcat_div_filter, 1)">  <img style="    height: 20px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
    </div>
    </div>
    </div>
    {{ checkElemetScroll(subcat_div_filter, subcat_div_filter_left, subcat_div_filter_right) }}
  </ng-container>



  <ng-container *ngIf="this.sub2cat && this.sub2cat.length>0 && this.filter.menu != 4">
    <div class="row">
      <div class="col-xs-12">
      <div class="col-xs-1" style="width:20px;padding:0px;    top: 24px;">
    <a #sub2cat_div_filter_left href="javascript:void(0)" (click)="divmove(sub2cat_div_filter, 0)">  <img style="    height: 20px;    margin-right: 5px;" alt="<" src="assets/images/lefticon.png">  </a>
    </div>
    <div class="col-xs-10" style="width:calc(100% - 70px); padding:0px">

    <div #sub2cat_div_filter class="Categories text-center">
      <ul  style="margin-bottom: 15px;" >
        <a style="border-radius: 20px; border-color: #e1e6ee;    "  *ngIf="this.filter.menu!=-1" [class]=" this.filter.sub2cat==null ? 'btn color_head' : 'btn btn-default color_black'"  (click)="this.stopScrollF(false);this.filter.sub2cat=null;this.calcnewurl('', 'u', 0)" id_input ="sub2cat"  >{{ 'all' | translate }}</a>
        <ng-container *ngFor="let subscat of this.sub2cat;  "  >
          <a style="border-radius: 20px; border-color: #e1e6ee;    " [class]=" this.filter.sub2cat==subscat.id ? 'btn color_head' : 'btn btn-default color_black'"  id_input ="sub2cat"  (click)="this.stopScrollF(false);this.filter.sub2cat=subscat.id; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 'u', subscat.id);" >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
        </ng-container>
        <ng-container *ngIf="this.sub2catStock == 1 && this.filter.menu == 1">
          <!-- &&  this.userLogging -->
          <a style="border-radius: 20px; background-color: #ff0000; color: #fff;"  [class]="this.filter.menu==-1 ? 'btn color_head' : 'btn btn-default color_black '" (click)="this.stopScrollF(false); this.calcUrlPromotions();">{{ 'stockoff' | translate }}</a>
        </ng-container>
      </ul>
    </div>

  </div>
    <div class="col-xs-1" style="width:20px;  top: 24px;">
    <a #sub2cat_div_filter_right href="javascript:void(0)" (click)="divmove(sub2cat_div_filter, 1)">  <img style="    height: 20px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
    </div>
    </div>
    </div>
    {{ checkElemetScroll(sub2cat_div_filter, sub2cat_div_filter_left, sub2cat_div_filter_right) }}
  </ng-container>


</div>

<div class="container-fluid c-container-50">



  <div class="c-layout-sidebar-content c-border-2x noPadding c-margin-t-10 c-margin-b-20">





    <div class="row">


      <div class="col-lg-12 col-xs-12 c-bg-white    ">
        <div class="pull-left c-relative   full-width " >
          <div class="btn c-theme-bg c-font-white c-btn-square cartBtn float-left pr-30 filter-sm-12 filter-icon" style="margin-bottom: 15px;" (click)="openmenumobile()"><i class="fa fa-filter"></i> {{ 'filter_by' | translate }}</div>

        </div>
      </div>

      <div class="col-lg-12 col-xs-12 c-bg-white c-margin-b-10  ">
        <div class="FiltersApplied">
          <div class="FiltersApplied__Container  ">



            <ng-container *ngIf="this.filter.brand!=null && this.filter.brand!='' && this.brands && this.brands.length>0 && this.filter.menu!=4">
              <ng-container *ngFor="let b of stringtoarray(this.filter.brand);">
              <div class="Applied">
                  <span data-size="small"> {{ getname(b, this.brands) }} </span>
                <a class="filterShow" type="button" href="javascript:void(0)" (click)="addFilterMulti(b, 'brand');getItems(0)" ><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
              </ng-container>
            </ng-container>


            <ng-container *ngIf="this.filter.color!=null && this.filter.color!='' && this.colors && this.colors.length>0">
              <ng-container *ngFor="let b of stringtoarray(this.filter.color);">
              <div class="Applied">
                  <span data-size="small"> {{ getname(b, this.colors) }} </span>
                <a class="filterShow" type="button" href="javascript:void(0)" (click)="addFilterColor(b);getItems(0)" ><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
              </ng-container>
            </ng-container>



            <ng-container *ngIf="this.filter.genre!=null && this.filter.genre!='' && this.genres && this.genres.length>0">
              <ng-container *ngFor="let b of stringtoarray(this.filter.genre);">
              <div class="Applied">
                  <span data-size="small"> {{ getname(b, this.genres) }} </span>
                <a class="filterShow" type="button" href="javascript:void(0)" (click)="addFilterMulti(b, 'genre');getItems(0)" ><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="this.filter.size!=null && this.filter.size!='' && this.sizes && this.sizes.length>0">
              <ng-container *ngFor="let b of stringtoarray(this.filter.size);">
              <div class="Applied">
                  <span data-size="small"> {{ getname(b, this.sizes) }} </span>
                <a class="filterShow" type="button" href="javascript:void(0)" (click)="addFilterSize(b);getItems(0)" ><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="this.filter.countryorigin!=null && this.filter.countryorigin!='' && this.countriesOrigin && this.countriesOrigin.length>0 ">
              <ng-container *ngFor="let b of stringtoarray(this.filter.countryorigin);">
              <div class="Applied">
                  <span data-size="small"> {{ getname(b, this.countriesOrigin) }} </span>
                <a class="filterShow" type="button" href="javascript:void(0)" (click)="addFilterMulti(b, 'countryorigin');getItems(0)" ><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="(this.filter.brand!=null && this.filter.brand!='' && this.brands && this.brands.length>0 && this.filter.menu!=4) || (this.filter.color!=null && this.filter.color!='' && this.colors  && this.colors.length>0) || (this.filter.genre!=null && this.filter.genre!='' && this.genres  && this.genres.length>0) || (this.filter.size!=null && this.filter.size!='' && this.sizes  && this.sizes.length>0) || (this.filter.countryorigin!=null && this.filter.countryorigin!='' && this.countriesOrigin  && this.countriesOrigin.length>0)">
              <button type="button" style="padding: 6px 15px 4px 15px;" (click)="this.stopScrollF(false);cleanFilters();closeModalFilters();this.closefiltersBody();" class="btn btn-default  ">{{ 'clean_filters' | translate }}</button>
            </ng-container>

        </div>
      </div>
    </div>



    <!-- douball product start -->
    <div   class="prodHolder2 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12" style="display: inline-block;float: none !important; height: 100%;">
              <!-- douball product start -->
              <div class="noPadding"  >
    <div class="row  "   id="prodHolder" infiniteScroll style=" float: none !important;"
    [infiniteScrollDistance]="1.5"
    [infiniteScrollThrottle]="150" [immediateCheck]="true"  [scrollWindow]="true"  [alwaysCallback]="true"
          (scrolled)="onScroll($event)" (scroll)="onScrollw($event)" >


    <ng-container *ngIf="this.itemsList && this.translate.currentLang  ">

      <ng-container *ngFor="let item of this.itemsList; trackBy: trackByFn; let ia=index;let lastp=last; ">

        <div class="col-md-4 col-lg-3   col-xs-12 col-sm-6 productWrapper c-margin-b-10 c-bg-white " id="prodBox{{ item.iditem }}">
          <div>

            <a [href]='[this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(item.langs[this.translate.currentLang].description)+"-"+item.iditem)]' target="_blank"  (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem)"   >
              <div class="imgProd">
                <img  [lazyLoad]="loadImg(item)" [defaultImage]="this.browserwebp ? defaultImage : loadImg(item) " alt="{{ item.langs[this.translate.currentLang].description  }} - {{ 'Name_APP' | translate }}"   style="display: inline;">
                <div *ngIf="  item.novelty==1 && ((item.datelimitnovelty && item.datelimitnovelty>=item.datenow) || !item.datelimitnovelty ) && !item.withPromotion" class="prodNovelty">{{ 'new' | translate }}</div> 						</div>
                <div *ngIf="  item.withPromotion && item.withPromotion==1" class="prodPromo"> -{{ showDiscount( item ) }}%</div>
                <!--  && this.userLogging -->
                <div class="madeinportugal" *ngIf="item.countryOriginy && item.countryOriginy === 'Portugal'"><img src="assets/images/pt.png" alt="Portugal" width="30" height="30"></div>
                <div class="madeinportugal" *ngIf="item.countryOriginy && item.countryOriginy === 'Italy'"><img src="assets/images/it.png" alt="Italy" width="30" height="30"></div>
              </a>
              <div class="colorsLg c-margin-t-5 text-center col-xs-12 noPadding prodColorImg" *ngIf="  item.itemwithattr==1   ">
                <ng-container   *ngIf="    item.colors && objectKeys(item.colors).length>0 ">
                  <ng-container   *ngFor="let color of  item.colors | keyvalue: returnZero ; let i=index    ">

                    <ng-container *ngIf="  i<3   ">
                      <a [href]='[this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(item.langs[this.translate.currentLang].description)+"-"+item.iditem)+"/"+ color.key+"/"+ size(item, color.key)]' target="_blank"  (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"   >
                        <div style="display:inline-block;width:50px; height:50px; border:1px solid #ccc;margin-right:5px;background-size:100% 100%;">
                          <img [src]="getBackgorundColor(color.value)" [alt]="item.langs[this.translate.currentLang].description + ' '+ createImageAltAttr(color.value) + ' '+('Name_APP' | translate)" 
                          style="height: 100%;width: 100%">
                        </div>
                      </a>
                    </ng-container>


                    <ng-container *ngIf="  item.colors &&  i==3 ">
                      <a [href]='[this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(item.langs[this.translate.currentLang].description)+"-"+item.iditem)+"/"+ color.key+"/"+ size(item, color.key)]' target="_blank" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"       >
                        <div style="display:inline-block;width:50px; height:50px; border:1px solid #ccc;margin-right:5px;background-size:100% 100%;">
                          <img src="assets/images/plus.png" alt="{{ 'see_all' | translate }}" style="height: 100%;width: 100%">
                        </div>
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <div class="colorsXs colorsSm colorsMd c-margin-t-5 text-center col-xs-12 noPadding prodColorImg" *ngIf="  item.itemwithattr==1   ">
                <ng-container   *ngIf="    item.colors && objectKeys(item.colors).length>0 ">
                  <ng-container   *ngFor="let color of  item.colors | keyvalue ; let i=index    ">

                    <ng-container *ngIf="  i<2 || objectKeys(item.colors).length==3  ">
                      <a [href]='[this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(item.langs[this.translate.currentLang].description)+"-"+item.iditem)+"/"+ color.key+"/"+ size(item, color.key)]' target="_blank" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"      >
                        <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px;background-size:100% 100%;">
                          <img [src]="getBackgorundColor(color.value)" [alt]="item.langs[this.translate.currentLang].description + ' '+ createImageAltAttr(color.value)+' '+('Name_APP' | translate)" style="height: 100%;width: 100%">
                        </div>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="  item.colors &&  i==2 && objectKeys(item.colors).length!=3 ">
                      <a [href]='[this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(item.langs[this.translate.currentLang].description)+"-"+item.iditem)+"/"+ color.key+"/"+ size(item, color.key)]' target="_blank" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"      >
                        <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px;background-size:100% 100%;">
                          <img src="assets/images/plus.png" alt="" style="height: 100%;width: 100%">
                        </div>
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <div class="prodCont">

                <span style="    min-height: 44px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center hidden-xs hidden-sm">{{ parseText(item.langs[this.translate.currentLang].description, 38)  }}</span>
                <span style="    min-height: 44px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center hidden-md hidden-lg">{{ parseText(item.langs[this.translate.currentLang].description, 70)  }}</span>
                <div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
                  <div class="">
                    <ng-container *ngIf="this.userLogging">
                      <del *ngIf="item.withPromotion && item.withPromotion==1">{{ showPrice(item, 1) }}€</del>
                      <ins>{{ showPrice(item, 2) }}€</ins>
                    </ng-container>
                  </div>

                  <!-- <ng-container *ngIf="this.userLogging"> -->
                    <a href="javascript:;" data-iditem="{{ item.iditem }}" (click)="openModal(item, ia)" class="hoverPrice hidden-xs hidden-sm">
                      <div class="col-sm-12 col-xs-12 noMargin c-theme-bg">
                        <ins style="color: #FFF;">{{ 'buyfast' | translate }}</ins>
                      </div>
                    </a>
                  <!-- </ng-container> -->

                </div>



              </div>

            </div>
          </div>
          <ng-container *ngIf="lastp">{{ this.viewInit() }}</ng-container>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="this.emptyItem  ">
        {{ this.viewInit() }}
        <div class="col-md-12 col-sm-12 col-xs-12 col-sm-12 productWrapper divnoproductcheck c-margin-b-10 c-bg-white "  >
          {{ 'noproductsfound' | translate }}
        </div>
      </ng-container>

    </div>

</div>
</div>

    <div class="centerRegular" >
      <!-- <div *ngIf="loadingShow" class="loada small" id="prodLoading"   ></div> -->
      <ng-container *ngIf="loadingShow">
        <div class="noPadding">
          <div class="col-md-4 col-lg-3 col-xs-12 col-sm-6 productWrapper c-margin-b-10 c-bg-white" *ngFor="let i of loadingItems">
            <div>
              <a href="javascript:;">
                <div class="imgProd imgProd-loading">
                  <img [src]="defaultImage" style="display: inline;">
                </div>
              </a>
              <div class="colorsLg c-margin-t-5 text-center col-xs-12 noPadding prodColorImg">
                <ng-container *ngFor="let color of loadingColors">
                  <a href="javascript:;">
                    <div style="display:inline-block;width:50px; height:50px; border:1px solid #ccc;margin-right:5px;background-size:100% 100%;" [ngStyle]="{ 'background-image': 'url(' + defaultImage + ')'}"></div>
                  </a>
                </ng-container>
                <a href="javascript:;">
                  <div style="display:inline-block;width:50px; height:50px; border:1px solid #ccc;margin-right:5px;background-size: 100% 100%;">
                    <img src="assets/images/plus.png" alt="" style="height: 100%;width: 100%">
                  </div>
                </a>
              </div>
              <div class="prodCont" style="height: 65px;">
                <div class="sketlon-text-container">
                  <div class="sketlon-text title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center hidden-xs hidden-sm"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>



    <h4 style="margin-top: 50px;" *ngIf="this.relateditemarr && this.relateditemarr.length>0" class="pt text-center"><b>{{ 'suggestprod' | translate }}</b></h4>

    <br>
    <div class="col-xs-12" *ngIf="this.relateditemarr && this.relateditemarr.length>0">
			<div class="clearfix"></div>
			<div class="col-xs-12   noPadding">



				<ng-container *ngFor="let product of this.relateditemarr; let i_product=index ">
					<div class="col-md-2 col-sm-4 col-xs-12 productWrapper c-margin-b-20 c-bg-white c-related-prod">
            <!-- add onclick="return false;" remove target="_blank" remove comments ulrmount -->
						<a rel="noopener" [href]='this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(product.langs[this.translate.currentLang].description)+"-"+product.iditem) ' target="_blank" (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem )">
							<div class="imgProd c-border c-border-grey-4">
								<img [alt]="product.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="getoneimg(product)">
							</div>
							<div class="prodCont c-border noBorder-top c-border-grey-4">
								<span style="min-height: 47px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center">{{ product.langs[this.translate.currentLang].description }}</span>
								<div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
									<span  ><ins class="c-font-16 c-theme-font"> {{ getPriceRelated(product) }} €</ins></span>&nbsp;
								</div>
								</div>
							</a>
						</div>
					</ng-container>




				</div>
			</div>



    <div class="col-xs-12 col-md-12 pb-60">
      <div class="col-xs-12 "><ng-container *ngIf="this.descTextMenu && this.descTextMenu[this.translate.currentLang] && this.descTextMenu[this.translate.currentLang].descriptioncomplete"> <span [innerHTML]="this.descTextMenu[this.translate.currentLang].descriptioncomplete" ></span></ng-container> </div>
      <div class="col-xs-12 "><ng-container *ngIf="this.descText && this.descText[this.translate.currentLang] && this.descText[this.translate.currentLang].descriptioncomplete"> <span [innerHTML]="this.descText[this.translate.currentLang].descriptioncomplete" ></span></ng-container> </div>
    </div>


  </div>
</div>
</div>




<modal #ModalDetail  id="buyFast" >
  <modal-header>



  </modal-header>
  <modal-content>


    <ng-container *ngIf="this.selectItem   ">

      <div class="row" >
        <div class="col-md-12">
          <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
          <h3 class="c-font-16 c-font-sbold name_item noMargin">{{ this.selectItem.langs[this.translate.currentLang].description }}<span class="noPadding c-margin-b-10 helveticaltsd c-font-13"> / {{ 'refa' | translate }} : {{ this.selectItem.code }} | </span><label class="helveticaltsd c-font-13 showcolor" *ngIf="this.selectItem.itemwithattr">{{ this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].name }}</label></h3>
        </div>
      </div>

      <div class="row">
        <div class="imagesProd col-md-10 col-md-offset-1">
          <div class="col-lg-12  setSameHeight  hidden-sm hidden-xs">
            <div class="row">
              <div class=" c-product-gallery-content" style="display: block">
                <div class="col-xs-12 c-product-img c-zoomDetail img0 " img_order="0">

                  <img *ngIf="this.selectItem.itemwithattr==0" class="c-bg-grey" [alt]="this.selectItem.langs[this.translate.currentLang].description+' - '+ Name_APP|translate" [src]="(this.browserwebp && this.selectItem.images[0].webp) ? this.apiService.imagesDir+this.selectItem.images[0].webp :  this.apiService.imagesDir+this.selectItem.images[0].photo" img_order="0">

                  <img *ngIf="this.selectItem.itemwithattr==1" class="c-bg-grey" [alt]="this.selectItem.langs[this.translate.currentLang].description+' - '+ Name_APP|translate" [src]="(this.browserwebp && this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images[0].webp) ? this.apiService.imagesDir+this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images[0].webp :  this.apiService.imagesDir+this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images[0].photo" img_order="0">
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="formProd col-md-12 c-margin-b-10"><div class="clearfix"></div>

        <span *ngIf="this.selectItem" class="c-custom-labels" data-toggle="buttons">
          <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">
            <ng-container   *ngFor="let size of  color.value    | keyvalue ; let i1=index    ">


              <label [class]="(this.selectItem.colorActive==color.key && this.selectItem.sizeActive==size.key) ? 'active' : ''" class="btn c-btn-square" >

                <div class="labelColor" style="background:url('{{
                  (this.browserwebp && color.value[objectKeys(color.value)[0]].images[0].webp) ? this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].webp : this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].photo }}'); background-size: 100% 100%;"><img alt="{{ this.selectItem.langs[this.translate.currentLang].description   }} - {{ showcolorInfo(color,2) }} - {{ 'Name_APP' | translate }}" src="{{
                    (this.browserwebp && color.value[objectKeys(color.value)[0]].images[0].webp) ? this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].webp : this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].photo }}">
                  </div>

                  <input name="color" data-colorname="{{ size.value.number }}" (change)="changeColor(color.key, size.key)" value="{{ size.key }}" type="radio">
                  <div class="text-center col-xs-12 noPadding">
                    <ng-container *ngIf="this.userLogging">
                      <p class="noMargin c-font-12 " *ngIf="  this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  "><del>{{ loadPriceFastBuy(1) }}€</del></p>
                      <p class="noMargin c-font-13 c-theme-font c-font-bold">{{ loadPriceFastBuy(2) }}€</p>
                    </ng-container>
                    <p class="noMargin c-font-10 c-font-bold">{{ 'sizeabbr' | translate }}: {{ size.value.number }}</p>
                    <p class="noMargin c-font-10 c-font-bold">{{ 'stock' | translate }}: {{ size.value.stock | number: '1.0-0' }}</p>
                    <div class="c-spinner-invert quantWrapper" style="margin: 0 auto !important;width: 40px;">
                      <input [id]="'qtd_stock_'+this.selectItem.iditem+'_'+color.key+'_'+size.key"  class="form-control input-sm c-square quantInput   prodQuant" data_max="{{ size.value.stock }}" data_min="0"  (change)="stockajust(size.value.stock, this.selectItem.iditem, color.key, size.key)"  [value]="this.selectItem.colors[color.key][size.key].qtdCart>0 ? parseIntValue(this.selectItem.colors[color.key][size.key].qtdCart) : ''"  type="text"><div class="clearfix"></div>
                      <div class="noPadding" style="width:40px !important;">
                        <button class="btn btn-xs btn-default c-square " (click)="descProd(size.value.stock, this.selectItem.iditem, color.key, size.key)" style="width:20px;float:left;padding:1px 3px !important;" type="button" data_min="0" data_input="c-item-7017-6-1"><i class="fa fa-minus" style="font-size:10px;"></i></button>
                        <button class="btn btn-xs btn-default c-square noMargin" (click)="incProd(size.value.stock, this.selectItem.iditem, color.key, size.key)" style="width:20px;float:left;padding:1px 3px !important;" type="button" data_input="c-item-7017-6-1"><i class="fa fa-plus" style="font-size:10px;"></i></button>
                      </div>
                    </div>
                  </div>
                </label>
              </ng-container>
            </ng-container>
          </span>

          <div class="clearfix"></div><div style="margin-top:10px" class="col-sm-12 noPadding"><button type="button" (click)="buyProduct()" class="btn btn-block c-theme-bg c-font-white c-btn-square cartBtn">{{ 'add_to_cart' | translate }}</button></div></div>
        </div>

      </ng-container>




    </modal-content>
  </modal>










  <modal #ModalFilterMobile    id="ModalFilterMobile" >
    <modal-header>

      <div class="row">
        <div class="col-xs-12" style="text-align:center">
          <b>{{ 'FILTER_ORDER' | translate }}</b>
          <div class=" pull-right">

            <button type="button" (click)="closeModalFilters();this.closefiltersBody();" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>

          </div>
        </div>

      </div>

    </modal-header>
    <modal-content>

      <div id="menuFilters" style="  width: 100%; display: block;">
        <div class="row">







          <div id="aspect-content">





            <div class="aspect-tab " *ngIf="this.filter.menu!=4">
              <input id="brand-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="brand-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'brand' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="removedots"  >
                    <li  > <label  style="width:100%;font-weight: 200;" id_input ="brand"  >{{ 'allbrands' | translate }}  <input type="checkbox" class="pull-right" value="null" name="brands_" [checked]="this.filter.brand==null" (change)="this.filter.brand=null;" ></label></li>
                    <ng-container *ngFor="let bran of this.brands;  "  >
                      <li  > <label style="width:100%;font-weight: 200;"  id_input ="brand"  >{{ bran.description}}   <input type="checkbox" class="pull-right" value="{{ bran.idbrand }}" name="brands_" [checked]="checkFilterSelMult(bran.idbrand, 'brand')" (change)="addFilterMulti(bran.idbrand, 'brand') " ></label></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>





            <div class="aspect-tab ">
              <input id="color-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="color-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'color' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="removedots"  >
                    <li  > <label  style="width:100%;font-weight: 200;" id_input ="codecolor"  >{{ 'allcolors' | translate }} <input type="checkbox" class="pull-right" value="null" name="codecolor_" [checked]="this.filter.color==null" (change)="this.filter.color=null" ></label></li>
                    <ng-container *ngFor="let col of this.colors;   "  >
                      <li  > <label style="width:100%; font-size: 16px;font-weight: 200; "  id_input ="codecolor"  > <span  *ngIf="col.code" class="button_color_li" style="  background-color:#{{ col.code }}"></span><span *ngIf="!col.code" class="button_color_li multicolorbackground"   ></span>&nbsp;&nbsp;&nbsp;{{ col.langs[this.translate.currentLang] ? col.langs[this.translate.currentLang].description : col.description }} <input type="checkbox" class="pull-right" value="{{ col.idcolor }}" name="codecolor_" [checked]="checkcolorSel(col.idcolor)" (change)="addFilterColor(col.idcolor)" ></label></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>

            <div class="aspect-tab ">
              <input id="size-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="size-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'size' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="removedots"  >
                    <li  > <label  style="width:100%;font-weight: 200;" id_input ="codesize"  >{{ 'all' | translate }} <input type="checkbox" class="pull-right" value="null" name="codecolor_" [checked]="this.filter.size==null" (change)="this.filter.size=null" ></label></li>
                    <ng-container *ngFor="let size of this.sizes;  "  >
                      <li  > <label style="width:100%;font-weight: 200;"  id_input ="size"  >
                        {{ size.description | translate }}
                           <input type="checkbox" class="pull-right" value="{{ size.id }}" name="sizes_" [checked]="checkFilterSelMult(size.idsize, 'size')" (change)="addFilterMulti(size.idsize, 'size') " ></label></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>


            <div class="aspect-tab ">
              <input id="genre-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="genre-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'genre' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="removedots"  >
                    <li  > <label  style="width:100%; font-weight: 200;" id_input ="gen"  >{{ 'unisex' | translate }}  <input type="checkbox" class="pull-right" value="null" name="gen_" [checked]="this.filter.genre==null" (change)="this.filter.genre=null" ></label></li>
                    <ng-container *ngFor="let gen of this.genres;  "  >
                      <li  > <label style="width:100%; font-weight: 200;"  id_input ="gen"  >{{ gen.langs[this.translate.currentLang] ? gen.langs[this.translate.currentLang].description : gen.description }} <input type="checkbox" class="pull-right" value="{{ gen.idgenre }}" name="gen_" [checked]="checkFilterSelMult(gen.idgenre, 'genre')" (change)="addFilterMulti(gen.idgenre, 'genre') " ></label></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>



            <div class="aspect-tab ">
              <input id="countryorigin-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="countryorigin-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'countryorigin' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="removedots"  >
                    <li  > <label  style="width:100%;font-weight: 200;" id_input ="countryorigin"  >{{ 'all' | translate }}  <input type="checkbox" class="pull-right" value="null" name="countryorigin_" [checked]="this.filter.countryorigin==null" (change)="this.filter.countryorigin=null" ></label></li>
                    <ng-container *ngFor="let bran of this.countriesOrigin;  "  >
                      <li  > <label style="width:100%;font-weight: 200;"  id_input ="countryorigin"  >{{ bran.langs[this.translate.currentLang] ? bran.langs[this.translate.currentLang] :  bran.description}}  <input type="checkbox" class="pull-right" value="{{ bran.id }}" name="countryorigin_" [checked]="checkFilterSelMult(bran.id, 'countryorigin')" (change)="addFilterMulti(bran.id, 'countryorigin') " ></label></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>





            <div class="aspect-tab ">
              <input id="price-item-1" type="checkbox" class="aspect-input" name="aspect">
              <label for="price-item-1" class="aspect-label"></label>
              <div class="aspect-content">
                <div class="aspect-info">
                  <span class="aspect-name">{{ 'price' | translate }}</span>
                </div>
              </div>
              <div class="aspect-tab-content" style="  padding-left: 0px;">
                <div class="sentiment-wrapper">
                  <ul class="  removedots "  >
                    <li  >  <label  style="width:100%;font-weight: 200;"  id_input ="orderField"   >{{ 'withoutorder' | translate }} <input  type="radio" class="pull-right" name="price_" value="0" [checked]="this.orderItem==0"  (change)="this.orderItem=0" > </label>       </li>
                    <li  >  <label  style="width:100%;font-weight: 200;"  id_input ="orderField"  >{{ 'orderasc' | translate }} <input type="radio" class="pull-right" value="1" name="price_" [checked]="this.orderItem==1" (change)="this.orderItem=1" ></label>   </li>
                    <li >  <label    style="width:100%;font-weight: 200;" id_input ="orderField"   >{{ 'orderdesc' | translate }} <input type="radio" class="pull-right" value="2" name="price_" [checked]="this.orderItem==2" (change)="this.orderItem=2" ></label>   </li>
                  </ul>
                </div>
              </div>
            </div>



            <div class="aspect-tab "  >
              <div  style=" margin-left: 15px; margin-right: 35px;">
                <div class="aspect-info" style="width:100%">
                  <label  style="width:100%"  id_input ="novelty"   >{{ 'new' | translate }} <input  type="checkbox" class="pull-right" name="novelty_" value="1" [checked]="this.filter && this.filter.novelty==1" id="new_filter" (change)="this.filterNewItem()" > </label>
                </div>
              </div>
            </div>





          </div>







        </div>
      </div>





    </modal-content>

    <modal-footer>

      <button type="button" style="padding: 6px 15px 4px 15px;" (click)="this.stopScrollF(false);cleanFilters();closeModalFilters();this.closefiltersBody();" class="btn btn-default pull-left">{{ 'clean_filters' | translate }}</button>
      <button type="button" style="padding: 6px 15px 4px 15px;" (click)="this.stopScrollF(false);getItems(0);closeModalFilters();this.closefiltersBody();"  class=" c-theme-btn btn pull-right">{{ 'search' | translate }}</button>

    </modal-footer>
  </modal>
