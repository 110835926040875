<div class="overlay overlayMenu" style="display:none;"></div>
<header class="c-layout-header c-layout-header-4 c-layout-header-default-mobile" data-minimize-offset="10">
	<div style=" " class="c-paddingHeader c-topbar c-topbar-light c-small-header c-theme-bg c-solid-bg" [class.hide-element]="this.fixedMenuMobile">
		<div class="container-fluid c-container-50">
			<div class="col-md-3 noPadding hidden-xs hidden-sm  hidden-md"><span *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang]" class="c-font-17 c-xxs-font-13 c-font-white"><i class="fa fa-phone   c-xxs-font-14"></i> {{ this.companyPresentationArr[this.translate.currentLang].contact  }}</span> <span class="contactobs" *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang] && this.companyPresentationArr[this.translate.currentLang].contactobs"> {{ this.companyPresentationArr[this.translate.currentLang].contactobs }}</span> </div>
			<div class="col-md-6 text-center hidden-xs hidden-sm  hidden-md"> 
				<!--<ng-container>
					<span *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang] && this.freeShipLimit > 0" class="c-font-17 c-xxs-font-13 c-font-white" [innerHTML]=" replacementString(('free_ship' | translate) ,  this.freeShipLimit, 1) "></span><span class="c-font-15 c-xxs-font-13 c-font-white"> {{this.countryDescription}}</span>
				</ng-container> -->
				<!-- <ng-template #showothertitle> -->
					<span *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang]" class="c-font-17 c-xxs-font-13 c-font-white">{{this.companyPresentationArr[this.translate.currentLang].title_header}}</span>
				<!--</ng-template> -->
			</div>
			<div class="col-md-3 noPadding text-right hidden-xs hidden-sm hidden-md">
				{{ 'SECUREPAYMENT' | translate }}   			</div>

				<div class="col-xs-12 text-center hidden-lg "><span *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang] && this.freeShipLimit > 0" class="c-font-17 c-xxs-font-13 text-small c-font-white"><i class="fa fa-phone   c-xxs-font-14"></i> {{ this.companyPresentationArr[this.translate.currentLang].contact  }}</span> <span class="contactobsmob" *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang] && this.companyPresentationArr[this.translate.currentLang].contactobs"> {{ this.companyPresentationArr[this.translate.currentLang].contactobs }}</span></div>
				<div class="col-xs-12 text-center hidden-lg  "> 
					<!--<span *ngIf="  this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang]" class="c-font-17 c-xxs-font-12 text-small c-font-white" [innerHTML]=" replacementString(('free_ship' | translate) ,  this.freeShipLimit, 1) "></span> <span class="c-font-17 text-small c-font-white"> {{this.countryDescription}}</span> -->
					<span *ngIf="this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang]" class="c-font-17 c-xxs-font-12 text-small c-font-white">{{this.companyPresentationArr[this.translate.currentLang].title_header}}</span>
				</div>
				<div class="col-xs-12 text-center hidden-lg  ">
					{{ 'SECUREPAYMENT' | translate }}  		</div>
				</div>
			</div>

			<div style="" class="c-paddingHeader c-topbar c-topbar-light c-solid-bg c-small-header" [class.hide-element]="this.fixedMenuMobile">
				<div class="container-fluid c-container-50 c-mobile-container">

					<nav class="c-top-menu c-pull-left col-md-9 col-xs-12 pb-xs-10">
						<ul class="c-icons c-theme-ul c-font-14">

						<ng-container *ngIf="this.showpoints; else showaccount">
							<li *ngIf="userLogging" (click)="this.showMenu = false;" (mouseenter)="cartLeave()"><a class="c-font-black c-font-17 c-xxs-font-13" [href]="['/points'] | localize" onclick="return false;" [routerLink]="['/points'] | localize"><i class="fa fa-user c-theme-color c-xxs-font-14"></i> {{ 'myaccount' | translate }}</a></li>
						</ng-container>
						<ng-template #showaccount>
							<li *ngIf="userLogging" (click)="this.showMenu = false;" (mouseenter)="cartLeave()"><a class="c-font-black c-font-17 c-xxs-font-13" [href]="['/account'] | localize" onclick="return false;" [routerLink]="['/account'] | localize"><i class="fa fa-user c-theme-color c-xxs-font-14"></i> {{ 'myaccount' | translate }}</a></li>
						</ng-template>

							<ng-container *ngIf="userLogging && docs_pending>0">
								<li class='c-divider'>|</li><li>
							<a class='c-font-red c-font-17 c-xxs-font-13' [href]="['/orders'] | localize" onclick="return false;" [routerLink]="['/orders'] | localize" >
							 {{ replacementString( ('count_orders_finish' | translate) , docs_pending) }}
							</a></li>

							</ng-container>
						<!--
					--><li *ngIf="!userLogging"><a class="c-font-black c-font-17 c-xxs-font-13"  (click)="this.showMenu = false;"  [href]="['/register'] | localize" onclick="return false;" [routerLink]="['/register'] | localize"><i class="fa fa-user-plus c-theme-color c-xxs-font-14"></i> {{ 'create_account' | translate }}</a></li><!--
				--><li *ngIf="!userLogging" class="c-divider mr-10 ml-10">|</li><li *ngIf="!userLogging"><a (click)="this.showMenu = false;" class="c-font-black c-font-17 c-xxs-font-12" [href]="['/signin'] | localize" onclick="return false;" [routerLink]="['/signin'] | localize"  ><i class="fa fa-lock c-theme-color c-xxs-font-14"></i> {{ 'clientarea' | translate }}</a></li><!--
			-->				</ul>
		</nav>

		<nav class="c-top-menu c-pull-left col-md-4 col-sm-6 visible-sm visible-xs visible-md">
			<ul class="c-ext pull-left c-theme-ul right-wrapper searchUl fullSize">
				<li class="c-search-toggler-wrapper fullSize">

					<label style="display:none" for="search"></label>
					<input #searchInput type="text" name="search" (keyup.enter)="searchItem(searchInput);" placeholder="{{ 'search' | translate }}" class="line-search valRequired" />
					<span class="c-pointer c-font-14 c-ab-pointer" (click)="searchItem(searchInput)"><i class="fa fa-search"></i></span>

				</li>
			</ul>
		</nav>

		<nav class="c-top-menu c-pull-right col-md-3">
			<ul class="c-ext pull-right c-theme-ul right-wrapper">
				<li class="c-lang" style="top: 0;margin-right: 20px !important">
					<div class="header-dropdown" style="position: relative; width: 25px;">
						<a class="c-font-bold" href="javascript:;" (click)="showLangs()">
							<img [src]="'assets/images/' + this.translate.currentLang + '.png'" [alt]="getLang(this.translate.currentLang)" class="counrty-lang">
						</a>
						<div class="header-menu" style="top: 150%;">
							<ul>
								<li *ngFor="let lang of languagesPermit; let last = last;" style="width: 140px;" class="nav-item" [class.selected]="lang == this.translate.currentLang" (click)="hideLangs()">
									<img [src]="'assets/images/' + lang + '.png'" [alt]="getLang(lang)" class="counrty-lang-inner">
									<a href="javascript:;" (click)="this.localize.changeLanguage(lang); this.savelang(lang);">{{getLang(lang)}}</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
				<!-- <li *ngIf="userLogging && showpoints" class="c-cart-toggler-wrapper" style="margin-right: 20px !important;">
					<a onclick="return false;" class="c-btn-icon c-cart-toggler">
						<i><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg></i>
						<span class="c-cart-number c-theme-bg cartItemCount" style="top: -5px;">
							{{this.qtdPoints ? this.qtdPoints : 0}}
						</span>
					</a>
				</li> -->
				<li  *ngIf="userLogging" class="c-cart-toggler-wrapper" style="margin-right: 20px !important;" [routerLink]="['/wishlist'] | localize">
					<a onclick="return false;" class="c-btn-icon c-cart-toggler">
						<i><svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px; color: #9facba;"  width="20" height="20" color="9facba" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
							<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
						  </svg></i>
						<span class="c-cart-number c-theme-bg cartItemCount" style="top: -6px;" *ngIf="this.qtdWishlist && this.qtdWishlist > 0">{{ this.qtdWishlist ?  this.qtdWishlist : 0  }}</span>
					</a>
				</li>
				<li  *ngIf="!userLogging" class="c-cart-toggler-wrapper" style="margin-right: 20px !important;" [routerLink]="['/signin'] | localize">
					<a onclick="return false;" class="c-btn-icon c-cart-toggler">
						<i><svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px; color: #9facba;"  width="20" height="20" color="9facba" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
							<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
						  </svg></i>
						<span class="c-cart-number c-theme-bg cartItemCount" style="top: -6px;" *ngIf="this.qtdWishlist && this.qtdWishlist > 0">{{ this.qtdWishlist ?  this.qtdWishlist : 0  }}</span>
					</a>
				</li>
				<li (mouseenter)="cartHover()"    class="c-cart-toggler-wrapper c-last">
					<a  [routerLink]="['/checkout'] | localize" onclick="return false;" [href]="['/checkout'] | localize" class="c-btn-icon c-cart-toggler">
						<i class="icon-handbag 1 c-cart-icon"></i>
						<span class="c-cart-number c-theme-bg cartItemCount">{{ this.cart ?  this.cart.length : ''  }}</span>
					</a>
				</li>
				
			</ul>
		</nav>

		<div class="c-brand c-pull-left col-md-12 text-center hidden-sm hidden-xs hidden-md">
			<a [href]="['/'] | localize" (click)="cartLeave();" (mouseenter)="cartLeave()" onclick="return false;" [routerLink]="['/'] | localize"   class="c-logo">
				<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-desktop-logo" width="200px">
				<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-desktop-logo-inverse" width="200px">
				<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-mobile-logo" width="200px">
			</a>
		</div>

	</div>
</div>


<div class="c-navbar" [class.fixed-mobile]="this.fixedMenuMobile">
	<div class="container-fluid c-container-50 c-mobile-container">
		<div class="c-navbar-wrapper clearfix">

			<div class="c-brand c-pull-left visible-md visible-sm visible-xs">
				<a [href]="['/'] | localize" (click)="this.showMenu = false;"  onclick="return false;" [routerLink]="['/'] | localize"  class="c-logo">
					<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-desktop-logo" width="200px">
					<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-desktop-logo-inverse" width="200px">
					<img src="assets/images/layout/logos/logo.png" alt="ModaServerPRO" class="c-mobile-logo" width="200px">
				</a>
				<button class="c-hor-nav-toggler" (click)="this.showMenu ? closeMenu() : openMenu()" type="button" data-target=".c-mega-menu">
					<span class="c-line"></span>
					<span class="c-line"></span>
					<span class="c-line"></span>
					<span class="name_menu uppercase">{{ 'MENU' | translate }}</span>
				</button>
				<button   (click)="cartisHover()"   class="c-cart-toggler" type="button">
					<i class="icon-handbag 2"></i>
					<span class="c-cart-number c-theme-bg cartItemCount">{{ this.cart ?  this.cart.length : ''  }}</span>
				</button>
				<button *ngIf="userLogging" class="c-cart-toggler" type="button" [routerLink]="['/wishlist'] | localize">
					<i><svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px; color: #9facba;"  width="20" height="20" color="9facba" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
						<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
					  </svg></i>
					<span class="c-cart-number c-theme-bg cartItemCount" style="top: -3px;" *ngIf="this.qtdWishlist && this.qtdWishlist > 0">{{ this.qtdWishlist ?  this.qtdWishlist : 0  }}</span>
				</button>
				<button *ngIf="!userLogging" class="c-cart-toggler" type="button" [routerLink]="['/signin'] | localize">
					<i><svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px; color: #9facba;"  width="20" height="20" color="9facba" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
						<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
					  </svg></i>
					<span class="c-cart-number c-theme-bg cartItemCount" style="top: -3px;" *ngIf="this.qtdWishlist && this.qtdWishlist > 0">{{ this.qtdWishlist ?  this.qtdWishlist : 0  }}</span>
				</button>
				<!-- <button *ngIf="userLogging && showpoints" class="c-cart-toggler" type="button">
					<i><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg></i>
					<span class="c-cart-number c-theme-bg cartItemCount" style="top: -3px;">{{this.qtdPoints ? this.qtdPoints : 0}}</span>
				</button> -->
			</div>


			<div class="c-quick-search"  >
				<input type="text" name="query" placeholder="{{ 'search' | translate }}" value="" class="form-control" autocomplete="off">
				<span class="c-theme-link">&times;</span>
			</div>



			<nav [class]="this.showMenu ? 'c-mega-menu c-pull-right c-mega-menu-light c-mega-menu-light-mobile c-fonts-uppercase col-lg-9 c-shown' : 'c-mega-menu c-pull-right c-mega-menu-light c-mega-menu-light-mobile c-fonts-uppercase col-lg-9' "   style="float:left !important;" [class.position-absolute]="this.fixedMenuMobile">
				<ul class="nav navbar-nav c-theme-nav" (mouseenter)="changeshowMenu(true)" (mouseleave)="changeshowMenu(false)">


					<ng-container *ngIf="this.menu">
						<ng-container *ngFor="let menucicle of this.menu; let i_n=index;  ">
							{{ checkPos(i_n) }}
							<ng-container>
								<li  [class]="checkMenuActive(i_n)  ">
									<a       href="javascript:void(0);" [style]="menustyle(menucicle)" class="c-link menu_p  dropdown-toggle" [routerLink]="menucicle.link==1 ? (['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate |  urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu)   ] | localize) : []" (click)="verifyclick(1,menucicle.link,i_n)">
										<span (click)="menucicle.link==1 ? closeMenu() : ''" class="">{{ menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | uppercase ) : menucicle.description  }}</span>  <span  *ngIf="menucicle.type && menucicle?.level_info"  class="c-arrow c-toggler" (click)="verifyclick(2,menucicle.link,i_n)"></span> 							</a>
	
	
	
										<ng-container *ngIf="menucicle.type && menucicle?.level_info">
											<div class="dropdown-menu c-menu-drop c-border-top c-menu-type-mega c-menu-type-fullwidth" [style]="this.showMenu ? ' ' : 'display:none'" style="min-width: auto">
												<div class="container-fluid c-container-50 c-mobile-container seven-cols">
	
	
													<div class="col-md-12 col-lg-1 noPadding-left col-xs-12 pull-left" *ngFor="let submenu of menucicle.level_info;  ">
														<ul class="dropdown-menu c-menu-type-inline">
															<li><a (click)="closeMenu()" href="javascript:void(0)" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.description | urlpipe | lowercase )+'-'+submenu.attr1+submenu.id  ] | localize)">{{ submenu.description }}</a></li>
	
															<ng-container *ngIf="submenu?.level_info  "  >
																<ng-container *ngFor="let submenu2 of submenu.level_info; "  >
																	<li class="subLi"><a  (click)="closeMenu()" href="javascript:void(0)" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.description | urlpipe | lowercase )+'-'+submenu.attr1+submenu.id, (submenu2.description | urlpipe | lowercase )+'-'+submenu2.attr1+submenu2.id  ] | localize)">{{ submenu2.description }}</a></li>
																</ng-container>
															</ng-container>
	
	
														</ul>
													</div>
	
												</div>
											</div>
										</ng-container>
	
									</li>	
							</ng-container>
							<!-- <ng-template #notloggedin>
								<li  [class]="checkMenuActive(i_n)  " *ngIf="menucicle.description != 'stockoff'">
									<a       href="javascript:void(0);" rel="dofollow" [style]="menustyle(menucicle)" class="c-link menu_p  dropdown-toggle" [routerLink]="menucicle.link==1 ? (['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate |  urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu)   ] | localize) : []" (click)="verifyclick(1,menucicle.link,i_n) ">
										<span (click)="menucicle.link==1 ? closeMenu() : ''" class="">{{ menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | uppercase ) : menucicle.description  }}</span>  <span  *ngIf="menucicle.type && menucicle?.level_info"  class="c-arrow c-toggler" (click)="verifyclick(2,menucicle.link,i_n)"></span> 							</a>
	
	
	
										<ng-container *ngIf="menucicle.type && menucicle?.level_info">
											<div class="dropdown-menu c-menu-drop c-border-top c-menu-type-mega c-menu-type-fullwidth" [style]="this.showMenu ? ' ' : 'display:none'" style="min-width: auto">
												<div class="container-fluid c-container-50 c-mobile-container seven-cols">
	
	
													<div class="col-md-12 col-lg-1 noPadding-left col-xs-12 pull-left" *ngFor="let submenu of menucicle.level_info;  ">
														<ul class="dropdown-menu c-menu-type-inline">
															<li><a (click)="closeMenu()" href="javascript:void(0)" rel="dofollow" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.description | urlpipe | lowercase )+'-'+submenu.attr1+submenu.id  ] | localize)">{{ submenu.description }}</a></li>
	
															<ng-container *ngIf="submenu?.level_info  "  >
																<ng-container *ngFor="let submenu2 of submenu.level_info; "  >
																	<li class="subLi"><a  (click)="closeMenu()" href="javascript:void(0)" rel="dofollow" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.description | urlpipe | lowercase )+'-'+submenu.attr1+submenu.id, (submenu2.description | urlpipe | lowercase )+'-'+submenu2.attr1+submenu2.id  ] | localize)">{{ submenu2.description }}</a></li>
																</ng-container>
															</ng-container>
	
	
														</ul>
													</div>
	
												</div>
											</div>
										</ng-container>
	
									</li>
							</ng-template> -->
							
							</ng-container>
						</ng-container>







						<ng-container *ngIf="this.menu && this.menu.length>0">
							<li class=" "><a class="c-link" href="javascript:void(0)" (click)="closeMenu()" [routerLink]="(['/contact'] | localize)">{{ 'contacts' | translate }} 	<span class="c-arrow c-toggler"></span></a></li>
							<li class=" "><a class="c-link" href="javascript:void(0)" (click)="closeMenu()" [routerLink]="(['/blog'] | localize)">{{ 'blog' | translate }} 	<span class="c-arrow c-toggler"></span></a></li>
						</ng-container>

					</ul>

				</nav>


				<nav class="c-top-menu c-pull-left col-md-3 noPadding hidden-md hidden-sm hidden-xs">
					<ul class="c-ext pull-left c-theme-ul right-wrapper searchUl fullSize">
						<li class="c-search-toggler-wrapper fullSize">

							<input #searchInput2 type="text" name="search" (keyup.enter)="searchItem(searchInput2);" placeholder="{{ 'search' | translate }}" class="line-search valRequired" />
							<span class="c-pointer c-font-14 c-ab-pointer" (click)="searchItem(searchInput2)" ><i class="fa fa-search"></i></span>

						</li>
					</ul>
				</nav>
			</div>

			<div    class="c-cart-menu" id="cartWrapper">
				<div *ngIf="!(!this.cart || this.cart.length<=0)" class="c-cart-menu-title">
					<p class="c-cart-menu-float-l c-font-sbold"><span class="cartItemCount">{{ this.cart ?  this.cart.length : ''  }}</span> {{ 'product' | translate }}(s)</p>
					<p *ngIf="userLogging" class="c-cart-menu-float-r c-theme-font c-font-sbold"><span *ngIf="this.cartTotal" class="cartTotalItems">{{ this.cartTotal.totalTxt }}</span>€</p>
				</div>
				<ul class="c-cart-menu-items">
					<li *ngIf="!this.cart || this.cart.length<=0"><h5>{{ 'noproductscart' | translate }}</h5></li>

<ng-container *ngIf="this.cart && this.cart.length>0 && this.translate.currentLang ">
						<li *ngFor=" let ct of  this.cart; let i=index  ">
							<div class="c-cart-menu-close"><a href="javascript:;" (click)="removeItemCart(ct, i)"  class="c-theme-link">×</a></div>
							<a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description  |  urlpipe | lowercase)+ '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)">
								<img [alt]="ct.langs[this.translate.currentLang].description" [src]="cartImg(ct)">
							</a>
							<div class="c-cart-menu-content"><p>{{ ct.quantity }} <span class="c-item-price c-theme-font" *ngIf="userLogging">x {{ cartPrice(ct,2) }}€</span></p>
								<a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description  |  urlpipe | lowercase)+ '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)" class="c-item-name c-font-sbold">{{ cartDesc(ct)  }}</a>
							</div>
						</li>
</ng-container>


				</ul>
				<div class="c-cart-menu-footer">
					<a onclick="return false" *ngIf="this.cart && this.cart.length>0" (click)="cartisHover()" [routerLink]="[this.translate.currentLang+'/checkout']" [href]="this.translate.currentLang+'/checkout'" class="btn btn-md c-btn c-btn-square c-theme-btn c-font-white c-font-bold c-center c-font-uppercase c-font-14">{{ 'checkout' | translate }}</a>
				</div>
			</div>

		</div>
	</div>
</header>



<!-- PAGE CONTENT -->
<div (click)="cartLeave()" (mouseenter)="cartLeave()" class="c-layout-page">





		<router-outlet (activate)='onActivate($event)'></router-outlet>




</div>

<ng-container *ngIf="showGlobalbanner">
<ng-container *ngIf="  this.globals_banners && this.globals_banners[this.localize.parser.currentLang] && (this.globals_banners[this.localize.parser.currentLang][6] || this.globals_banners[this.localize.parser.currentLang][7] || this.globals_banners[this.localize.parser.currentLang][8]) ">

<div class="c-content-box c-size-md c-bg-white  ">
<div class="container-fluid c-container-50">
	<div class="c-content-feature-2-grid" data-auto-height="true" data-mode="base-height">
		<div class="row">
			<ng-container *ngIf="this.globals_banners[this.localize.parser.currentLang][6]">
			<div class="col-md-4 col-sm-6">
				<div class="c-content-feature-2p mobileMargin c-bg-grey5 text-center" data-height="height">
					<i class="c-font-30 c-theme-color fa fa-car c-margin-b-10"></i>
					<div class="clearfix"></div>
					<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.globals_banners[this.localize.parser.currentLang][6][0].description)"></div>
				</div>
			</div>
			</ng-container>
			<ng-container *ngIf="this.globals_banners[this.localize.parser.currentLang][7]">
			<div class="col-md-4 col-sm-6">
				<div class="c-content-feature-2p mobileMargin c-bg-grey5 text-center" data-height="height">
					<i class="c-font-30 c-theme-color glyphicon glyphicon-headphones c-margin-b-10"></i>
					<div class="clearfix"></div>
					<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.globals_banners[this.localize.parser.currentLang][7][0].description)"></div>
				</div>
			</div>
			</ng-container>
			<ng-container *ngIf="this.globals_banners[this.localize.parser.currentLang][8]">
			<div class="col-md-4 col-sm-6">
				<div class="c-content-feature-2p c-bg-grey5 text-center" data-height="height">
					<i class="c-font-30 c-theme-color glyphicon glyphicon-refresh c-margin-b-10"></i>
					<div class="clearfix"></div>
					<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.globals_banners[this.localize.parser.currentLang][8][0].description)"></div>
				</div>
			</div>
			</ng-container>
		</div>
	</div>
</div>
</div>
</ng-container>
</ng-container>



<a name="footer"></a>
<div class="container-fluid  c-container-50 c-margin-b-20">
	<span class="title_h3 c-font-uppercase c-font-bold c-font-black">{{ 'newsletter' | translate }}</span>
<form  [formGroup]="newsletterRegister" [ngClass]="{ 'is-invalid': submittedNews && frm.email.errors }" >
	<div class="input-group c-square" [class]="submittedNews && frm.email.errors ? 'has-error' : ''">
		<input formControlName="email" name="email" type="text" #emailNewletterInput class="form-control c-square c-theme input valRequired valEmail" placeholder="{{ 'write_your_email' | translate }}" />
		<span class="input-group-btn"><button type="button" (click)="newsletterInput(emailNewletterInput.value, emailNewletterInput)" class="btn c-theme-bg c-btn-square c-font-black c-font-14 c-font-lower">{{ 'newsletter_subscribe' | translate }}</button></span>
	</div>
</form>
<div class="points-description" *ngIf="this.userLogging && newsletterpoints && newsletterpoints.points">
	<span>
		<ng-container *ngIf="newsletterpoints.points>1">
			{{('newsletter_points_multiple' | translate).replace(":1:", newsletterpoints.points)}}
		</ng-container>
		<ng-container *ngIf="newsletterpoints.points==1">
			{{('newsletter_points' | translate).replace(":1:", newsletterpoints.points)}}
		</ng-container>
	</span>
</div>
<div *ngIf="this.submittedNews && frm.email.errors" class="invalid-feedback">
		 																 						   <div style="color:red" *ngIf="frm.email.errors.required">{{ 'insert_email' | translate }}</div>
		 																 						   <div style="color:red" *ngIf="frm.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
																									 </div>
</div>
<div class="clearfix"></div>
<footer class="c-layout-footer c-layout-footer-3 c-bg-grey">
	<div class="c-prefooter noPadding">
		<div class="container-fluid c-container-50">
			<div class="row c-margin-t-30 full-width">
				<div class="col-sm-9 col-lg-10 noPadding">
					<div class="col-sm-4">
						<div class="c-container c-first noMargin" *ngIf="this.companyInfo && this.companyPresentationArr && this.companyPresentationArr[this.translate.currentLang]">
							<div class="c-margin-b-10"><img src="assets/images/layout/logos/logo.png" width="200px" alt="ModaServerPRO" class="c-desktop-logo"></div>
							<p class="c-font-13">
								<i class="c-theme-color fa fa-map-marker c-font-19 pull-left" style="min-height:50px;"></i>
								{{ this.companyInfo.address }}<br>{{ this.companyInfo.postalcode }} {{ this.companyInfo.locality }}							</p>
								<div class="clearfix"></div>								<p class="c-font-13">
									<i class="c-theme-color fa fa-phone c-font-16 pull-left"></i>
									{{ this.companyPresentationArr[this.translate.currentLang].contact }} {{this.companyPresentationArr[this.translate.currentLang].contactobs }}						</p>								<p class="c-font-13">
										<i class="c-theme-color fa fa-envelope-o c-font-16 pull-left"></i>
										{{ this.companyPresentationArr[this.translate.currentLang].email }}							</p>						</div>
									</div>



									<ng-container *ngIf="this.gap">
										<ng-container *ngFor="let gap_info2 of this.gap;let i_m = index; let last = last; let first = first;  ">

											<div class="col-sm-2">
												<div class="c-container noMargin">
													<span class="title_h3 c-font-uppercase c-font-bold c-font-black">&nbsp;</span>
													<ul class="c-links">

														<ng-container *ngFor="let gap_info of gap_info2;   ">

														<li><a href="javascript:void(0)" [routerLink]="(['/page',   (gap_info.subject | urlpipe | lowercase), gap_info.idgap  ] | localize)">{{ gap_info.subject }}</a></li>
															</ng-container>

															<ng-container *ngIf="last">
																<li><a href="javascript:void(0)" [routerLink]="(['/contact' ] | localize)">{{ 'contacts' | translate }}</a></li>
															</ng-container>

															<ng-container *ngIf="  first">
																<li> <a rel="noopener" href="https://www.livroreclamacoes.pt/inicio" target="_blank">{{ 'COMPLAINT_BOOK' | translate }}</a> </li>
															</ng-container>

													</ul>

												</div>
											</div>

										</ng-container>
									</ng-container>




												</div>

												<div class="col-sm-3 col-lg-2 noPadding">
													<div class="c-container noMargin">
														<ul class="c-links c-inline-list pull-right c-mobile-full c-mobile-container">
															<li class="noMargin">
																<img class="full-width  " width="150px" src="assets/images/hipay.png?v=2" alt="{{ 'payment_authorized' | translate }} ModaServerPro"/>
															</li>
														</ul>
													</div>
													<div style="clear:both;text-align:right;" class="c-container noMargin">




																</div>
													</div>

													<div class="col-xs-12">
														<div class="c-social-shares noPadding c-margin-t-10">
															<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-facebook" aria-label="Facebook" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.facebook" href="{{ this.companyPresentation.facebook }}" target="_blank"><i class="fa fa-facebook noBorder"></i> &nbsp;</a>

														<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-linkedin" aria-label="Linkedin" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.linkedin" href="{{ this.companyPresentation.linkedin }}" target="_blank"><i class="fa fa-linkedin noBorder"></i> &nbsp;</a>

												<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-pinterest" aria-label="Pinterest" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.pinterest" href="{{ this.companyPresentation.pinterest }}" target="_blank"><i class="fa fa-pinterest noBorder"></i> &nbsp;</a>


												<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-instagram" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.instagram" aria-label="Instagram" href="{{ this.companyPresentation.instagram }}" target="_blank"><i class="fa fa-instagram noBorder"></i> &nbsp;</a>
												<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-vimeo" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.vimeo" aria-label="Vimeo" href="{{ this.companyPresentation.vimeo }}" target="_blank"><i class="fa fa-vimeo noBorder"></i> &nbsp;</a>
												<a class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-twitter" aria-label="Twitter" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.twitter" href="{{ this.companyPresentation.twitter }}" target="_blank"><i class="fa fa-twitter noBorder"></i> &nbsp;</a>
												<a style="padding: 0px 10px 0px 0px !important;" class="btn btn-social c-btn-square c-btn-uppercase btn-md btn-youtube" aria-label="Youtube" rel="noopener" *ngIf="this.companyPresentation && this.companyPresentation.youtube" href="{{ this.companyPresentation.youtube }}" target="_blank"><i style="font-size: xxx-large !important;position: relative;" class="fa fa-youtube-play red-color noBorder"></i> &nbsp;</a>


																																																									</div>

														</div>

												</div>
											</div>
										</div>
										<div class="c-postfooter noPadding c-bg-black c-margin-t-10">
											<div class="container-fluid c-container-50">
												<div class="row c-margin-t-10 c-margin-b-10">
													<div class="col-xs-12 text-center">
														<p class=" c-font-white c-font-14">&copy; {{year}} Moda Server Pro
															<span class="c-font-white">{{ 'all_rights_reserved' | translate }}</span> by <a target="_blank" rel="dofollow" href="https://gobox.pt/" class=" c-font-white">gobox.pt</a>
														</p>
													</div>
												</div>
											</div>
										</div>
									</footer>

									<div class="c-layout-go2top">
										<img id="goTop" style="display:none"  alt="{{ 'gotop' | translate }}" (click)="gotoTop()" src="assets/images/seta.png" title="{{ 'gotop' | translate }}" />
									</div>



									<modal #ModalLanguages  id="ModalLanguages" >
										<modal-header>
											<button type="button" (click)="this.modalService.close(this.modalDetailRef);" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
										</modal-header>

										<modal-content>

											<span class="title_h3 c-font-24 c-font-sbold">{{ 'choosecountrylang' | translate }}</span>

											<div style="margin: 20px 0;" class="form-group">
	                  <select #countryallsite   name="country"     class="form-control full-width float-left   c-square countryControl" >
											<option value="" >{{ 'selectcountry' | translate }}</option>
											<ng-container *ngIf=" this.countries " >
	                    <ng-container *ngFor="let country of this.countries ;  ">
	                      <option [selected]="this.countrySel==country.abbreviation ? 'selected' : ''" value="{{ country.abbreviation }}" data-attr="{{ country.abbreviation }}" >{{ country.description[this.translate.currentLang] }}</option>
	                    </ng-container >
											 </ng-container >
	                  </select>
											</div>
											<div class="clearfix"></div>

											<div style="margin: 20px 0;" class="form-group">
					<label class="control-label" for="language">{{ 'selectlanguage' | translate }}  </label>
					<select name="language" #elemLang  class="form-control valRequired c-square" required="">
												<option  *ngFor="let lang of languagesPermit; let last = last;" [selected]="this.translate.currentLang==lang ? 'selected' : ''" value="{{ lang  }}"  >{{ lang | uppercase | translate   }}</option>
											</select>
				</div>


										</modal-content>

										<modal-footer>

											<button type="button" (click)="changeLang(elemLang, countryallsite)" class="btn c-btn-dark-1 btn c-btn-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup">{{ 'save' | translate }}</button>

										</modal-footer>
									</modal>







									<modal #ModalLogin  id="ModalLogin" >
										<modal-header>
											<button type="button" (click)="this.modalService.close(this.modalLoginRef);" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
										</modal-header>

										<modal-content>


											<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'clientarea' | translate }}</span>
											<form  [formGroup]="signinFormMn" (ngSubmit)="onSubmitLogin()">
												<div class="form-group">
													<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
													<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (change)="changeEmailClear($event)" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
													<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
													   <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
													   <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
													</div>
												</div>
												<div class="form-group">
													<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
													<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
													<div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
														<div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
														<div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
													</div>
												</div>


												<div class="form-group row">
													<div class="form-group col-sm-6 col-xs-6 pad0">
														<div class="c-checkbox">
															<input id="checkbox15" formControlName="rememberme" name="rememberme" class="c-check" type="checkbox">
															<label for="checkbox15" class="c-font-14 helveticaltsd">
																<span class="inc"></span>
																<span class="check"></span>
																<span class="box"></span> {{ 'remember_me' | translate }}</label>
														</div>
													</div>
													<div class=" col-md-6 col-xs-6 text-right float-left">
														<button type="submit" class="pull-right  btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup c-font-14 helveticaltsd "><span>{{ 'login' | translate }}</span></button>
													</div>
												</div>

												<div class="clearfix"></div>
												<div style="margin-bottom: 25px;" class="form-group">
													<div class="col-md-12 row  ">

							 						</div>
												</div>
											</form>

											<br>

												<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'create_account' | translate }}</span>
												<p  [innerHtml]="'register_text' | translate"></p>
												<div class="clearfix"></div>
												<a [routerLink]="(['/register'] | localize)" (click)="this.modalService.close(this.modalLoginRef);" href="javascript:void(0);" class="pull-right btn c-theme-bg btn-lg c-font-uppercase c-font-white c-btn-bold c-btn-square c-btn-signup c-font-14 helveticaltsd">
													<i class="fa fa-user-plus" style="top: -2px;position: relative;"></i>&nbsp;
													<span>{{ 'register' | translate }}</span>
												</a>



										</modal-content>

										<modal-footer>



										</modal-footer>
									</modal>


									<cookie-law   expiration="1" >{{ 'policy_cookie_desc' | translate }}
									  <ng-container *ngIf="this.gapCookiepage"><a href="javascript:void(0)" class="cookieMSG" [routerLink]="(['/page/'+   (this.gapCookiepage.subject | urlpipe | lowercase), this.gapCookiepage.idgap  ] | localize)">{{ 'policy_cookie' | translate }}</a></ng-container><ng-container *ngIf="!this.gapCookiepage">{{ 'policy_cookie' | translate }}</ng-container></cookie-law>
