import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { Observable } from  'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  // DEV
  
  /*
  PHP_API_SERVER = "http://localhost/modaserverpro/backend/index.php";
  readonly imagesDir: string = "https://goboxdeveloper.com/sergio/gesbox/bo/views/assets/img/";
  GET_TAGS_META=["robots", "description", "author", "keywords", "og:image", "og:title", "og:description"];
  PHP_API_URL_DEFAULT = "http://localhost/modaserverpro";
*/
  //PHP_API_SERVER = "https://goboxdeveloper.com/daniel/modaserver/backend/index.php";
  

  // PROD
  
  PHP_API_SERVER = "https://modaserverpro.com/backend/index.php";
  PHP_API_URL_DEFAULT = "https://modaserverpro.com";
  GET_TAGS_META=["robots", "description", "author", "keywords", "og:image", "og:title", "og:description"];
  readonly imagesDir: string = "https://gesbox.modaserverpro.com/bo/views/assets/img/";

  
  

  constructor(private httpClient: HttpClient) {}

  readCompany(drop: number, country:any, lang?:any): Observable<Object>{
    let paramextra = "";
    if(lang){
      paramextra = `&lang=${lang}`;
    }
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getinfo&drop=${drop}&country=${country}`+paramextra,  {withCredentials: true});
  }

  getrelateditem(iditem: any): Observable<Object> {
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getrelated&iditem=${iditem}`);
  }

  getItemsLimit(): Observable<Object> {
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getitemslimit`);
  }

  getPresentation(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpresentation&lang=${lang}`);
  }

  getDocumentsPendingPaid(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getdocpending`,  {withCredentials: true});
  }

  getBlogs(lang: String, search?:any): Observable<Object>{
    if(!search){
      search = "";
    }else{
      search = encodeURIComponent(search);
    }
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getblogs&lang=${lang}&search=${search}`);
  }

  getbreadcrumbs(bread: any, initialurl:any): Observable<Object> {
    var queryString = Object.keys(bread).map((key) => { return 'params['+encodeURIComponent(key)+']' + '=' + encodeURIComponent(bread[key]) }).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getbreadcrumbs&initialurl=`+initialurl+`&`+queryString);
  }

  getlanguages(drop: number): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getlanguages&drop=${drop}`);
  }

  getMenu(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getmenu&lang=${lang}`);
  }

  getGap(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getgap&lang=${lang}`);
  }

  getBlog(lang: String, limit: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getblog&lang=${lang}&limit=${limit}`);
  }


  getWarning(lang: String, type:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getwarning&lang=${lang}&type=${type}`,  {withCredentials: true});
  }

  getBlogbyid(lang:any, id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getblogbyid&lang=${lang}&id=${id}`);
  }

  getBanners(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getbanners`);
  }

  getGlobalBanners(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getglobalbanners`);
  }

  getItemsHomePage(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getitemshomepage`);
  }

  getBrandsFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getbrands&`+queryString);
  }

  getCountryOriginFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcountryorigin&`+queryString);
  }

  getSubCatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsubcat&`+queryString);
  }

  getCatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcat&`+queryString);
  }

  getSub2CatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsub2cat&`+queryString);
  }

  getColorsFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcolors&`+queryString);
  }

  getSizesFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsizes&`+queryString);
  }

  getGenresFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getgenres&`+queryString);
  }

  getItemsShow(filter: any, orderItem: any, page:any, lang:any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getitems&`+queryString+"&order="+orderItem+"&page="+page+"&lang="+lang, {withCredentials: true});
  }

  addCart(arr: any): Observable<Object>{
    var formData = new FormData();
    arr.forEach(function(element, index){
      Object.entries(element).forEach(([key, value]) => {
        if(value==null){
          value="";
        }
         formData.append("cart["+index+"]["+key+"]", value.toString());
      });
    });
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=cart&action=add`, formData, {withCredentials: true});
  }

  getCart(ischeckout:any): Observable<Object>{

    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=get&checkout=`+ischeckout,  {withCredentials: true});
  }

  getCartbyattr(country:any, shippingcompany:any, shippingmethod:any): Observable<Object>{

    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=get&checkout=1&country=`+country+`&shippingcompany=`+shippingcompany+`&shippingmethod=`+shippingmethod,  {withCredentials: true});
  }

  removeCart(arr:any): Observable<Object>{
    var formData = new FormData();
    arr.forEach(function(element, index){
      Object.entries(element).forEach(([key, value]) => {
        if(value==null){
          value="";
        }
         formData.append("cart["+index+"]["+key+"]", value.toString());
      });
    });
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=cart&action=remove`, formData, {withCredentials: true});
  }


  getItem(item:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=get&id=`+item,  {withCredentials: true});
  }

  getContacts(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=contactus`);
  }

  orderContact(contact:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=company&action=savecontactus&lang=`+lang, contact);
  }

  getPage(page:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpage&id=`+page);
  }

  newsletter(email:any, lang:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=newsletter&email=`+email+`&lang=`+lang, {withCredentials: true});
  }

  getCountry(idwebsite?:any): Observable<Object>{
    var queryStr = "";
    if(idwebsite){
      queryStr = "&idwebsite="+idwebsite;
    }
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getcountry`+queryStr,  {withCredentials: true});
  }

  getCallingcode(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getcallingcode`);
  }

  getpostalcode(postalcode:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpostalcode&postalcode=${postalcode}`);
  }


  createuserweb(form:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=createuserweb&lang=`+lang, form);
  }

  getShippings(countryclient:any, country:any, address:any, postalcode:any, locality:any): Observable<Object>{
    countryclient = encodeURIComponent(countryclient); country = encodeURIComponent(country);
    address = encodeURIComponent(address); postalcode = encodeURIComponent(postalcode);
    locality = encodeURIComponent(locality);  return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=getshippings&countryclient=`+countryclient+`&country=`+country+`&address=`+address+`&postalcode=`+postalcode+`&locality=`+locality ,  {withCredentials: true});
  }

  getShippingsAbbr(countryclient:any, country:any, address:any, postalcode:any, locality:any, idshippingmode:any): Observable<Object>{
    countryclient = encodeURIComponent(countryclient); country = encodeURIComponent(country);
    address = encodeURIComponent(address); postalcode = encodeURIComponent(postalcode);
    locality = encodeURIComponent(locality); idshippingmode = encodeURIComponent(idshippingmode);  return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=getshippingsabbr&idshippingmode=`+idshippingmode+`&abbreviation=1&countryclient=`+countryclient+`&country=`+country+`&address=`+address+`&postalcode=`+postalcode+`&locality=`+locality ,  {withCredentials: true});
  }

  getPickups(country:any, shippingcomp:any, postalcode:any): Observable<Object>{
    return this.httpClient.get<Object>(`https://gesboxsoftware.com/apges/api.php?controller=pickup&action=get&country=`+country+`&shippingcompany=`+shippingcomp+`&postalcode=`+postalcode);
  }


  addVoucher(voucher:any, email:any): Observable<Object>{
    voucher = encodeURIComponent(voucher);email = encodeURIComponent(email);
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=addvoucher&voucher=${voucher}&email=${email}`,  {withCredentials: true});
  }

  removeVoucher(idvoucher:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=removevoucher&idvoucher=${idvoucher}`,  {withCredentials: true});
  }


  buy(formData:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=order&action=buy&lang=`+lang, formData, {withCredentials: true});
  }

  changepayment(id:any, idpayment:any, lang:any, name:any, email:any, mbw:any): Observable<Object>{
    var formData = new FormData();
    formData.append("id", id.toString());
    formData.append("idpayment", idpayment.toString());
    formData.append("name", name.toString());
    formData.append("email", email.toString());
    if(mbw){
      formData.append("contact", mbw.toString());
    }
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=order&action=changepayment&lang=`+lang, formData);
  }

  getOrder(id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getorder&id=`+id);
  }

  getMetatags(id:any, type:any, lang:any, field?:any): Observable<Object>{
    if(field){
      return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=metatag&action=get&lang=`+lang+`&type=`+type+`&id=`+id+`&field=`+field);
    }
    else{
      return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=metatag&action=get&lang=`+lang+`&type=`+type+`&id=`+id);
    }
  }

  getUrlAlternate(params1?:any, params2?:any, params3?:any, params4?:any, params5?:any): Observable<Object>{
    var formData = new FormData();
    if(params1){
      formData.append("params1", params1);
    }
    if(params2){
      formData.append("params2", params2);
    }
    if(params3){
      formData.append("params3", params3);
    }
    if(params4){
      formData.append("params4", params4);
    }
    if(params5){
      formData.append("params5", params5);
    }
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=metatag&action=getUrlAlternate`, formData );
  }

  signin(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=signin&lang=`+lang, formData, {withCredentials: true});
  }

  logout(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=logout`,  {withCredentials: true});
  }

  getSession(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getsession`,  {withCredentials: true });
  }

  updateSession(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=updatesession`,  {withCredentials: true });
  }

  recoverPass(email:any, lang:any): Observable<Object>{
    email = encodeURIComponent(email);
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=recoverpass&email=${email}&lang=${lang}`);
  }


  emailCheck(email:any): Observable<Object>{
    email = encodeURIComponent(email);
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=checkemail&email=${email}`);
  }


  changepassword(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changepassword&lang=`+lang, formData, {withCredentials: true});
  }

  getUserInfo(lang:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getinfo&lang=`+lang, {withCredentials: true});
  }


  changeuserdata(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changedata&lang=`+lang, formData, {withCredentials: true});
  }

  createUserwebwithoutNewsletter(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=createsimple&lang=`+lang, formData, {withCredentials: true});
  }

  getShowDescription(type:any, id:any, type2:any, id2:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getshowdescription&type=`+type+`&id=`+id+`&type2=`+type2+`&id2=`+id2 );
  }


  loginFacebook(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=loginfacebook&lang=`+lang, formData, {withCredentials: true});
  }


  getordergenerate(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getordergenerate`, {withCredentials: true});
  }

  changelang(lang: String, country?:any): Observable<Object>{
    if(country){
      return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changelang&lang=${lang}&country=${country}`, {withCredentials: true});
    }else{
      return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changelang&lang=${lang}`, {withCredentials: true});
    }
  }
  addwishlist(iditem: any, idcolor: any): Observable<Object>{
    if(idcolor==null || idcolor==undefined){
      idcolor = "";
    }
    var formData = new FormData();
    formData.append("iditem", iditem);
    formData.append("idcolor", idcolor);
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=item&action=addwishlist`, formData,  {withCredentials: true});
  }


  getwishlist(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getwishlist`,  {withCredentials: true});
  }

  removewishlist(iditem: any, idcolor: any): Observable<Object>{
    if(idcolor==null || idcolor==undefined){
      idcolor = "";
    }
    var formData = new FormData();
    formData.append("iditem", iditem);
    formData.append("idcolor", idcolor);
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=item&action=removewishlist`, formData,  {withCredentials: true});
  }
  getWishlistItems(){
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getwishlistitems`, {withCredentials: true});
  }

  getProductReviews(lang:any, iditem:any, loadall:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getreviews&lang=${lang}&iditem=${iditem}&loadall=${loadall}`, {withCredentials: true});
  }
  reviewItem(formData, iditem: any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=item&action=reviewitem&iditem=${iditem}&lang=${lang}`,formData, {withCredentials: true});
  }
  getProductReview(id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getreviewbyid&iditemreview=${id}`);
  }
  getOrderItems(id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getorderitems&id=`+id, {withCredentials: true});
  }

  getPoints(type:any, typewebsite?:any): Observable<Object>{
    let extra="";
    if(typewebsite){extra = `&typewebsite=${typewebsite}`;}
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=getpointtype&type=${type}`+extra, {withCredentials: true});
  }

  getUserPoints(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=getuseravailablepoints`, {withCredentials: true});
  }

  generateLinkCode(iditem:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=generatelinkcode&iditem=${iditem}`, {withCredentials: true});
  }
  sendClickEvent(code:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=sendclickevent&code=${code}`, {withCredentials: true});
  }

  addPoints(points:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=addpoints&points=${points}`, {withCredentials: true});
  }

  getProductAccPoints(iditem, idcolor, idsize): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=calculateitempoints&id=${iditem}&idcolor=${idcolor}&idsize=${idsize}`, {withCredentials: true});
  }
  getSalesPoints(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=points&action=calculatesalespoints`, {withCredentials: true});
  }
}
