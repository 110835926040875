

<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'MY_CART' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>


<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">






		<div class="row">




					<div class="col-md-12 rightDivider">



							<div class="c-content-bar-1 c-align-left c-bordered c-bg-white">
								<ng-container *ngIf="this.cart && this.cart.length>0">

									<div class="table-responsive">
									<table class="table">
										<thead>
											<tr>
												<th class="c-font-16" colspan="2">{{ 'product' | translate }}</th>
												<th class="c-font-16">{{ 'unitprice' | translate }} <span *ngIf="this.clienttype==1" class="c-font-13"> {{ 'novat' | translate }}</span></th>
												<th class="c-font-16">{{ 'quantity' | translate }}</th>
												<th class="c-font-16">{{ 'total' | translate }} <span *ngIf="this.clienttype==1" class="c-font-13"> {{ 'novat' | translate }}</span></th>
												<th class="c-font-16">&nbsp;</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor=" let ct of  this.cart; let i=index  ">
												<td class="c-font-14 minw-img noBorder">
													<a class="c-theme-link c-font-11 noMargin"  [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+ ct.iditem, ct.idcolor, ct.idsize ] | localize)" href="javascript:void(0)">
														<img class="fixw-img" style="height:auto;" [src]="cartImg(ct)" [alt]="cartDesc(ct)">
													</a>
												</td>
												<td class="c-font-14 minw-name noBorder prodLinePad"><a class="c-theme-link"  [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+ ct.iditem, ct.idcolor, ct.idsize ] | localize)" href="javascript:void(0)">{{ cartDesc(ct)  }}</a></td>
												<td class="c-font-14 minw-price noBorder prodLinePad">
													<ng-container *ngIf="this.userLogging; else priceOnRequest">
														<ng-container *ngIf="ct.withPromotion && ct.withPromotion == 1">
															<p class="old-price">{{ cartPriceTxt(ct, 1) }}€</p>
														</ng-container>
														<p class="new-price" style="display: inline-block;">{{ cartPriceTxt(ct, 2) }}€</p>
													</ng-container>
													<ng-template #priceOnRequest>
														<p class="price-on-request" style="color:#FF0000;">{{ 'priceonrequest' | translate }}</p>
													</ng-template>
												</td>
												<td class="c-font-14 noBorder prodLinePad">
													<div class="c-input-group c-spinner quantWrapper">
														<input type="number" class="form-control   prodQuant" data-id=" "   value="{{ ct.quantity | number:'1.0' }}" (change)="stockajust($event, ct, 1)" [id]="'qtd_stock_'+ct.iditem+'_'+ct.idcolor+'_'+ct.idsize">
														<div class="c-input-group-btn-vertical">
															<button class="btn btn-default" (click)="incProd(ct)" type="button" data_input=" ">
																<i class="fa fa-plus" style="font-size:12px !important"></i>
															</button>
															<button class="btn btn-default" (click)="descProd(ct)" type="button" data_input=" ">
																<i class="fa fa-minus" style="font-size:12px !important"></i>
															</button>
														</div>
													</div>
												</td>
												<td class="c-font-14 minw-price noBorder prodLinePad">
													<ng-container *ngIf="this.userLogging; else priceOnRequestTotal">
														<p>{{ (cartPrice(ct, 2) * ct.quantity | currency:'':'').replace(',', ' ').replace('.', ',') }}€</p>
													</ng-container>
													<ng-template #priceOnRequestTotal>
														<p class="price-on-request" style="color:#FF0000;">{{ 'priceonrequest' | translate }}</p>
													</ng-template>
												</td>
												<td class="c-font-14 noBorder prodLinePad"><button type="button" class="  close pull-right" aria-label="Close" (click)="removeItemCart(ct, i)"><span aria-hidden="true">×</span></button></td>
											</tr>
										</tbody>
									</table>
								</div>

		<input type="hidden" name="weightTotal" id="weightTotal" value="5.395" wfd-invisible="true">




														</ng-container>

														<ng-container *ngIf="!this.cart || this.cart.length==0">
															<li class="row c-margin-b-15 c-margin-t-15" style="list-style:none;">
														<div class="col-md-8 c-font-16">
															<p class="c-font-16">{{ 'noproductscart' | translate }}</p>
														</div>
													</li>
															</ng-container>
							</div>



							<ng-container *ngIf="!this.userLogging">

								<div class="col-md-12">
									<div class="row log-reg-row">
										<div class="col-lg-6 col-md-6 col-xs-12 checkout-login-container">
											<div class="box-login"><h2>{{ 'checkout_login' | translate }}</h2></div>
											<div class="row mt-3 login-register-container">
												<form [formGroup]="signinForm" (ngSubmit)="onSubmitSignin()" style="margin-top: 20px;margin-bottom: 20px;">
													<div class="form-group col-sm-12">
														<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
														<input type="email" [ngClass]="{ 'is-invalid': submittedSign && fSign.email.errors }" (change)="changeEmailClear($event)" formControlName="email" id="email" class="form-control c-square valRequired valEmail" placeholder="{{ 'email' | translate }}" />
														<div *ngIf="this.submittedSign && fSign.email.errors" class="invalid-feedback">
														   <div *ngIf="fSign.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
														   <div *ngIf="fSign.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
														</div>
													</div>	
													<div class="form-group col-sm-12">
														<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
														<input type="password" id="password" [ngClass]="{ 'is-invalid': submittedSign && fSign.password.errors }" formControlName="password" class="form-control c-square valRequired"  placeholder="{{ 'password' | translate }}"/>
														<div *ngIf="this.submittedSign && fSign.password.errors" class="invalid-feedback">
															<div *ngIf="fSign.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
															<div *ngIf="fSign.password.errors.minlength">{{ 'min6char' | translate }}</div>
														</div>
													</div>	
													<div class=" col-md-6 col-xs-6"></div>	
													<div class=" col-md-6 col-xs-12 text-right float-left">
														<a (click)="openModalRecover();" href="javascript:void(0)" class="c-btn-forgot c-font-12 helveticaltsd "> {{ 'forgot_your_password' | translate }}</a>
													</div>
													<div class="clearfix"></div>
													<div style="margin-bottom: 25px;" class="form-group col-sm-12">
														<div class="col-md-12 row  ">
															<button type="submit" class="btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup c-font-14 helveticaltsd "><span>{{ 'login' | translate }}</span></button>
														 </div>
													</div>
												</form>
											</div>
											<ng-container *ngIf="this.ac_points">
												<ng-container *ngIf="this.ac_points.length >0; else singlepoint">
													<ng-container *ngFor="let point of this.ac_points">
														<div class="point-container text-center" *ngIf="point.idpointtype == 2">
															<ng-container *ngIf="point.points>1">
																<span class="points-description">{{('signin_points_multiple' | translate).replace(":1:", point.points)}}</span>
															</ng-container>
															<ng-container *ngIf="point.points==1">
																<span class="points-description">{{('signin_points' | translate).replace(":1:", point.points)}}</span>
															</ng-container>
														</div>
													</ng-container>
												</ng-container>
												<ng-template #singlepoint>
													<div class="point-container text-center" *ngIf="this.ac_points.idpointtype == 2">
														<ng-container *ngIf="point.points>1">
															<span class="points-description">{{('signin_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
														</ng-container>
														<ng-container *ngIf="point.points==1">
															<span class="points-description">{{('signin_points' | translate).replace(":1:", this.ac_points.points)}}</span>
														</ng-container>
													</div>
												</ng-template>
											</ng-container>
										</div>
										<div class="col-lg-6 col-md-6 col-xs-12 checkout-register-container">
											<div class="box-login">
												<h2>{{ 'no_account_click' | translate }} 
													<!-- <span class="link-login" >{{ 'here' | translate }}</span> -->
												</h2>
											</div>
											<div class="row mt-3 login-register-container login-register-container-mob">
												<p style="color: #000; font-weight: 600;">{{ 'click_btn_signin' | translate }}</p>
												<button class="btn c-theme-btn c-btn-square c-btn-uppercase c-btn-bold buyButton mt-20" [routerLink]="(['/register'] | localize)">
													<i class="fa fa-user-plus c-xxs-font-14"></i> {{ 'register' | translate }}
												</button>
											</div>
											<ng-container *ngIf="this.ac_points">
												<ng-container *ngIf="this.ac_points.length >0; else singlepoint">
													<ng-container *ngFor="let point of this.ac_points">
														<div class="point-container text-center" *ngIf="point.idpointtype == 1">
															<ng-container *ngIf="point.points>1">
																<span class="points-description">{{('register_points_multiple' | translate).replace(":1:", point.points)}}</span>
															</ng-container>
															<ng-container *ngIf="point.points==1">
																<span class="points-description">{{('register_points' | translate).replace(":1:", point.points)}}</span>
															</ng-container>
														</div>
													</ng-container>
												</ng-container>
												<ng-template #singlepoint>
													<div class="point-container text-center" *ngIf="this.ac_points.idpointtype == 1">
														<ng-container *ngIf="point.points>1">
															<span class="points-description">{{('register_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
														</ng-container>
														<ng-container *ngIf="point.points==1">
															<span class="points-description">{{('register_points' | translate).replace(":1:", this.ac_points.points)}}</span>
														</ng-container>
													</div>
												</ng-template>
											</ng-container>
										</div>
									</div>
								</div>
							</ng-container>

					</div>
					<div class="col-md-12 leftDivider" [style]="(this.cart && this.cart.length > 0) ? 'display:block' : 'display:none'">
										<form  [formGroup]="checkoutForm"  >
											
											<ul class="c-order list-unstyled" *ngIf="this.userLogging">
												<li class="row   c-margin-b-15"></li>


												<li class="row c-options c-margin-b-15">
													 <div   class="col-lg-6 col-md-6 col-xs-12   " >
													<div id="shipchoose_id_elem" class="col-lg-12 col-md-12 col-xs-12 c-font-14 helveticaltsd c-border-top shipchoose" style="padding: 0px 0px 0px 0px;">  <span _ngcontent-gwh-c94="" class="title_h3 c-font-16 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth">{{ 'shipchoose' | translate }}</span> </div>

													<div class="col-lg-8 col-md-8 col-xs-12 noBorderMob" style="padding-top:10px;">



														<div class="c-radio-list shippingContainer valRadio noMargin col-xs-12 col-sm-12 float-left pad0">
															<p *ngIf="!this.shippingInfo || this.shippingInfo.length==0" class='c-font-12 helveticaltsd'>{{ 'selectcountrytoship' | translate }}</p>



															<ng-container   *ngIf="this.shippingInfo">
																<ng-container   *ngFor="let ship of this.shippingInfo;let i=index;">
																	<div class="c-radio col-xs-12">
																		<input [checked]="this.selectShippingmode == ship.idshippingmode ? 'checked' : '' " (change)="this.selectShippingmode=ship.idshippingmode; this.pickupShippingArr = null;" value="{{ ship.idshippingmode }}" abrv="{{ ship.abbreviation }}" type="radio" name="shippingmethod" class="c-radio" id="radioS{{ ship.idshippingmode }}"  ><label class="c-font-bold c-font-14" for="radioS{{ ship.idshippingmode }}">
																			<span class="inc"></span><span class="check"></span><span class="box"></span> {{ 'shipping_'+ship.abbreviation | translate }}</label>
																	</div>


																	<div [hidden]="this.selectShippingmode!= ship.idshippingmode " class=" shippingCompanyClearAll  idshippingmode_{{ ship.idshippingmode }}">
																			<div class="col-xs-11 col-lg-11 pull-right shipCompanies">
																				<div class="valRadio">
																					<ng-container   *ngFor="let company of ship.companies;let last = last">
																					<div class="c-radio col-xs-12">
																						<input value="{{ company.idshippingcompany }}" type="radio" (click)="changeShippingCompany(company.idshippingcompany, company.abbreviation)" name="shippingcompany" class="c-radio"   id="radioC{{ company.idshippingcompany }}">
																							<label class="c-font-bold c-font-14" for="radioC{{ company.idshippingcompany }}">
																								<span class="inc"></span>
																								<span class="check"></span>
																								<span class="box"></span> {{ company.name | translate }}</label>

																								<ng-container *ngIf="company.lang && company.lang[this.translate.currentLang] && company.lang[this.translate.currentLang].description &&  company.lang[this.translate.currentLang].description != ''"><span> {{ company.lang[this.translate.currentLang].description }}</span></ng-container>
																					</div>

																					<ng-container  *ngIf="company.abbreviation=='CNP2'">
																					<div id="suport_pickup" *ngIf="this.pickupShipping">
																						<div id="div_pickup">
																							<li class="row c-options c-margin-b-15" id="codePickup"   >


																								<div class="col-md-12 col-xs-12  " style="padding-top: 10px;">
																									<div class="input-group c-square">
																										<input #codepostal_pickup id="codepostal_pickup" class="form-control c-square c-theme " maxlength="16" placeholder="{{ 'postalcod' | translate }}" type="text">
																										<span class="input-group-btn">
																											<button class="btn c-btn-grey-3 c-bg-black  " style="height: 35px; border-radius: 0px;" type="button" (click)="searchPickups(codepostal_pickup)">{{ 'search' | translate }}</button>
																										</span>
																									</div>
																								</div>
																							</li>

																							<li class="row c-options c-margin-b-15 " id="codePickupPoints"   >
																								<div class=" col-lg-12 col-md-12"  >
																									<div class="c-radio-list pointsPickup  noMargin  ">


																										<span *ngIf="this.pickupShippingArr && this.pickupShippingArr.length==0">{{ 'pickups_not_found' | translate }}</span>

																										 <!-- <div class="c-radio" *ngFor="let pickupS of this.pickupShippingArr;let ipick=index; ">
																											 <input (change)="this.pickupShippingIndex=ipick" value="{{ pickupS.number }}" type="radio" name="pickup"  class="c-radio" id="radioPCK{{ pickupS.number }}"  >
																											 <label class="c-font-bold c-font-14" style="line-height:normal;" for="radioPCK{{ pickupS.number }}"><span class="inc"></span><span class="check"></span><span class="box"></span> {{ pickupS.name }} - {{ pickupS.address }} </label>
																											</div> -->
																											<ng-container *ngIf="this.pickupShippingArr && this.pickupShippingArr.length > 0">
																												<agm-map style="height:400px" [latitude]="dpd_lat" [zoom]="zoom" [longitude]="dpd_lng">
																													<ng-container *ngFor="let point of this.pickupShippingArr; let ipick=index;">
																														<agm-marker [latitude]="point.latitude"
																														[longitude]="point.longitude" [iconUrl]="{ url: './assets/images/dpd-pickup-logo.png', scaledSize: { width: 40, height: 40, anchor : {x:19, y:19} }, labelOrigin:{x:12,y:27}}"
																															(markerClick)="this.pickupShippingIndex=ipick; markerClicked(point)">
																															<agm-info-window><h1 style="font-size: 12px;">{{point.name}}</h1></agm-info-window>
																														</agm-marker>
																													</ng-container>
																												</agm-map>
																												<ng-container *ngIf="this.pickupShippingIndex != -1">
																													<p class="c-font-14" style="margin-top: 15px;">{{ 'pickup_point_selected' | translate }}: {{pickup_selected.name}} <span (click)="openModalDetailPickup()"><span class="opening-hour">{{ 'see_opening_hours' | translate }} <i class="icon-arrow-right"></i></span></span></p>
																												</ng-container>
																											</ng-container>
																									</div>
																								</div> 
																							</li>
																						</div>
																					</div>
																					</ng-container>
																					<span *ngIf="last">{{  autoCheckShipp() }}</span>
																					</ng-container>

																				</div>
																			</div>
																		</div>

																 </ng-container>
															</ng-container>

														</div>

														<div class=" c-font-16 c-font-bold c-font-red c-margin-t-10 pull-left limitMsg" limit="0"  wfd-invisible="true">

															<span  *ngIf="this.selectShippingmode==1 && !this.selectShippingcompany" (mouseenter)="mouseStyle('span_ship_click_')" id="span_ship_click_" (click)="clickScroll('shipchoose_id_elem') ">{{ 'shipchoose' | translate }}  <br></span>

														</div>

													</div>
													</div>

													 <div   class="col-lg-6 col-md-6 col-xs-12   " >
													<div class="col-lg-12 col-md-12 col-xs-12 c-font-14 helveticaltsd c-border-top paymentWrapper  " id="paymentWrapperContainer" style="padding: 0px 0px 0px 0px;"> <span  _ngcontent-gwh-c94="" class="title_h3 c-font-16 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth">{{ 'paymentchoose' | translate }}</span>  </div>



													<div class="col-lg-12 col-md-12 col-xs-12   paymentWrapper noBorderMob" style="padding-top:10px;">



														<div class="c-radio-list paymentContainer valRadio noMargin">

															<ng-container *ngIf="!this.shippingInfo || this.shippingInfo.length==0">
																<span class="c-font-12 ">{{ 'select_country_payment_method' | translate }}</span>
																</ng-container>

															<ng-container   *ngIf="this.shippingInfo">
															<ng-container   *ngFor="let ship of this.shippingInfo;let i=index;">
															<ng-container   *ngFor="let payment of ship.payments;let last = last">
															<div [hidden]="this.selectShippingmode!= ship.idshippingmode " class="c-radio">
															<input value="{{ payment.idpaymentwebsite }}" (checked)="this.selectPayment=payment.idpaymentwebsite ? 'checked' : '' " (click)="this.selectPayment=payment.idpaymentwebsite"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}">
															<label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}">
															<span class="inc"></span><span class="check"></span>
															<span class="box"></span> {{ payment.abbr | translate  }}
															</label>

															<input autocomplete="new-password" type="hidden" value="{{ payment.abbr | translate  }}" id="text_radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">
														<input autocomplete="new-password" type="hidden" value="{{ payment.abbr }}" id="abbr_radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">

															</div>



															<ng-container   *ngIf="payment.abbr=='MBW' && this.selectShippingmode == ship.idshippingmode">
														<div [style]="(this.selectPayment==payment.idpaymentwebsite) ? 'display:block' : 'display:none'"  class="c-radio">
														  <label   class="control-label">{{ 'telephone' | translate }} <sup><i style="font-size: 9px;" class="c-theme-font pl-02   fa fa-asterisk"></i></sup></label>
														  <input style="margin-left: 30px;width: calc(100% - 15px);" type="text" [required]="(this.selectPayment==payment.idpaymentwebsite && this.selectShippingmode == ship.idshippingmode) ? 'true' : 'false'"      maxlength="20" id="telephone_mbw_{{ ship.idshippingmode }}"   class="form-control c-square valRequired valNumber" />
														</div>
													  </ng-container>


															<span *ngIf="last">{{  autoCheck() }}</span>
															</ng-container>
															</ng-container>
															</ng-container>

														</div>

<div class=" c-font-16 c-font-bold c-font-red c-margin-t-10 pull-left limitMsg" limit="0"  wfd-invisible="true">
<span *ngIf="this.shippingInfo && this.shippingInfo.length==0">{{ 'no_sells_for_your_country' | translate }}</span>
<span   *ngIf="!this.selectPayment && !(!this.shippingInfo) " (mouseenter)="mouseStyle('span_pay_click_') " id="span_pay_click_" (click)="clickScroll('paymentWrapperContainer')">{{ 'paymentchoose' | translate }} <br></span>
	</div>
													</div>
														</div>
												</li>







																		</ul>

											<div [style]="this.clienttype==1 ? 'display:none' : 'display:block'" class="shippingWrapper2 c-margin-t-40" *ngIf="this.userLogging">


												<div class="shippingaddressWrapper2" style="display:block;">
													<span class="title_h3 c-font-16 helveticaltsd c-font-bold ccblack  background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth">{{ 'databilling' | translate }}</span>


													<div class="row">
														<div class="form-group col-md-6">
															<label class="control-label" for="firstname_company">{{ 'name' | translate }} <i   class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i> </label>
															<input  [readonly]="(!this.userInfo || this.userInfo.name=='' || !this.userInfo.name) ? false : true  " autocomplete="new-password" type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" formControlName="firstname"  maxlength="150" id="firstname_company" value="" class="form-control c-square  " />
															<div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
																	 <div *ngIf="f.firstname.errors.required">{{ 'msg_valRequired' | translate }}</div>
															 </div>
														</div>
	
														<div class="form-group col-md-6">
															<label class="control-label" for="nif_company">{{ 'nif' | translate }} </label>
															<input (change)="emailChange()" autocomplete="new-password" type="text" [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" [readonly]="(!this.userInfo || this.userInfo.nif=='' || !this.userInfo.nif) ? false : true  " formControlName="nif"  maxlength="32" id="nif_company" value="" class="form-control c-square  " />
															<div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
																	 <div *ngIf="f.nif.errors.required">{{ 'msg_valRequired' | translate }}</div>
																	 <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
															 </div>
														</div>
													</div>




													<div class="row">
														<div class="form-group col-md-6" style="display: none;">
															<label class="control-label" for="email_company">{{ 'email' | translate }}<i   class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i> </label>
															<input autocomplete="new-password" (change)="changeEmail()" type="email" [ngClass]="{ 'is-invalid': (this.submitted || this.voucherError ) && f.email.errors }" formControlName="email"   maxlength="250" id="email_company" value="" class="form-control c-square  " />
															<div *ngIf="(this.submitted || this.voucherError ) && f.email.errors" class="invalid-feedback">
																<div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
								 <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
															 </div>
														</div>
													</div>


													<div class="row">
													<div class="form-group col-md-6">
														<label class="control-label" for="address">{{ 'address' | translate }} <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
														<input autocomplete="new-password" type="text"  name="address" #invoiceaddress  formControlName="address" maxlength="200"     class="form-control c-square"/>
														<div *ngIf="submitted && f.address.errors" class="invalid-feedback">
						                     <div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
						                 </div>
													</div>
													<div class="form-group col-md-6">
														<label class="control-label" for="address2">{{ 'addresscomplement' | translate }}  </label>
														<input autocomplete="new-password" type="text"  name="address2"  formControlName="address2" maxlength="200"     class="form-control c-square"/>
													</div>
													</div>

													<div class="row">
														<div class="form-group col-md-6">
															<label class="control-label" for="postalcode">{{ 'postalcod' | translate }}  <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
															<input  autocomplete="new-password" type="text"  name="postalcode" #invoicepostalcode  formControlName="postalcode" maxlength="32"     class="form-control c-square"/>
															<div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
							                     <div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
							                 </div>
														</div>
														<div class="form-group col-md-6">
															<label class="control-label" for="locality">{{ 'locality' | translate }} <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
															<input autocomplete="new-password" type="text" #invoicelocality     name="locality"  formControlName="locality" maxlength="150"     class="form-control c-square"/>
															<div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
							                     <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
							                 </div>
														</div>
													</div>



													<div class="form-group">
														<label class="control-label full-width float-left" for="country">{{ 'country' | translate }}  <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
														<select #invoicecountry autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.shipping_idcountry.errors }"  (change)="checkchangecountry()" formControlName="idcountry"  *ngIf=" this.countries " id="country"  class="form-control full-width float-left valRequired c-square countryControl" >


					                    <ng-container *ngFor="let country of this.countries ;  ">
					                      <option value="{{ country.fullid }}" data-attr="{{ country.abbreviation }}" >{{ country.description[this.translate.currentLang] }}</option>
					                    </ng-container >
					                  </select>

				                  <div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
				                     <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
				                 </div>
													</div>


<input autocomplete="new-password" type="hidden"  formControlName="lastname" id="obs" maxlength="250" value=""   />
												 </div>
											 </div>


<div class="clearfix"></div>






							<div class="shippingWrapper2 c-margin-t-15">


								<div class="shippingaddressWrapper2" style="display:block;" *ngIf="this.userLogging">
									<span class="title_h3 c-font-16 helveticaltsd c-font-bold ccblack  background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth">{{ 'addressinfo' | translate }}</span>

								 <div class="form-group" [style]="this.clienttype==1 ? 'display:block' : 'display:none'"  >
										<label class="control-label" for="shipping_company">{{ 'companyname' | translate }} </label>
										<input autocomplete="new-password" type="text" [ngClass]="{ 'is-invalid': submitted && f.shipping_company.errors }" formControlName="shipping_company"  maxlength="32" id="shipping_company" value="" class="form-control c-square  " />
										<div *ngIf="submitted && f.shipping_company.errors" class="invalid-feedback">
		                     <div *ngIf="f.shipping_company.errors.required">{{ 'msg_valRequired' | translate }}</div>
		                 </div>
									</div>

									<div class="row">
									<div class="form-group col-md-6">
										<label class="control-label" for="shipping_responsable"><span *ngIf="this.clienttype==1">{{ 'personincharge' | translate }}</span><span *ngIf="this.clienttype!=1">{{ 'personchargresp' | translate }}</span><i class="c-theme-font pl-02 c-font-12  fa fa-asterisk  "></i></label>
										<input autocomplete="new-password" type="text" [ngClass]="{ 'is-invalid': submitted && f.shipping_responsable.errors }" formControlName="shipping_responsable"  maxlength="32" id="shipping_responsable" value="" class="form-control c-square valRequired" />
										<div *ngIf="submitted && f.shipping_responsable.errors" class="invalid-feedback">
		                     <div *ngIf="f.shipping_responsable.errors.required">{{ 'msg_valRequired' | translate }}</div>
		                 </div>
									</div>
									<div class="form-group col-md-6">
										<label class="control-label" for="telephone">{{ 'telephone' | translate }} <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
										<input autocomplete="new-password" type="text" [ngClass]="{ 'is-invalid': submitted && f.mobilephone.errors }" formControlName="mobilephone" maxlength="20" id="telephone"   class="form-control c-square valRequired valNumber" />
										<div *ngIf="submitted && f.mobilephone.errors" class="invalid-feedback">
		                     <div *ngIf="f.mobilephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
		                 </div>
									</div>
									</div>

									<div class="row">
									<div class="form-group col-md-6">
										<label class="control-label" for="address">{{ 'address' | translate }} <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
										<input autocomplete="new-password" type="text"  name="shipping_address" (keyup)="checkmaxchars($event)"  #shippingaddress formControlName="shipping_address" maxlength="64"     class="form-control c-square"/>
										<div *ngIf="submitted && f.shipping_address.errors" class="invalid-feedback">
		                     <div *ngIf="f.shipping_address.errors.required">{{ 'msg_valRequired' | translate }}</div>
		                 </div>
									</div>
									<div class="form-group col-md-6">
										<label class="control-label" for="address2">{{ 'addresscomplement' | translate }}  </label>
										<input autocomplete="new-password" type="text"  name="shipping_address2" (keyup)="checkmaxchars($event)" formControlName="shipping_address2" maxlength="64"     class="form-control c-square"/>
									</div>
									</div>
									<div class="row">
										<div class="form-group col-md-6">
											<label class="control-label" for="postalcode">{{ 'postalcod' | translate }}  <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
											<input autocomplete="new-password" type="text"  name="shipping_postalcode" #shippingpostalcode formControlName="shipping_postalcode" maxlength="32"     class="form-control c-square"/>
											<div  *ngIf="submitted && f.shipping_postalcode.errors" class="invalid-feedback">
			                     <div *ngIf="f.shipping_postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
			                 </div>
										</div>
										<div class="form-group col-md-6">
											<label class="control-label" for="locality">{{ 'locality' | translate }} <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
											<input autocomplete="new-password" type="text" #shippinglocality  name="shipping_locality"  formControlName="shipping_locality" maxlength="32"     class="form-control c-square"/>
											<div *ngIf="submitted && f.shipping_locality.errors" class="invalid-feedback">
			                     <div *ngIf="f.shipping_locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
			                 </div>
										</div>
									</div>

									<div class="form-group">

										<label class="control-label full-width float-left" for="country">{{ 'country' | translate }}  <i class="c-theme-font pl-02 c-font-12 fa fa-asterisk"></i></label>
                  <select #shippingcountry autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.shipping_idcountry.errors }" formControlName="shipping_idcountry" (change)="changecountry()"  *ngIf=" this.countries " id="shipping_idcountry" class="form-control full-width float-left valRequired c-square countryControl" >


                    <ng-container *ngFor="let country of this.countries ;  ">
                      <option value="{{ country.fullid }}" data-attr="{{ country.abbreviation }}" >{{ country.description[this.translate.currentLang] }}</option>
                    </ng-container >
                  </select>

                  <div *ngIf="submitted && f.shipping_idcountry.errors" class="invalid-feedback">
                     <div *ngIf="f.shipping_idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 </div>

									</div>

								</div>
							</div>

											</form>

											<div class="clearfix"></div>


											<ng-container *ngIf="this.cart && this.cart.length>0">

				<!-- <div class="col-md-6" *ngIf="this.clienttype == 0 && !this.userLogging">
				<div class="box-login">
					<h2>{{ 'checkout_login' | translate }} <span class="link-login" (click)="openModalSignin();">{{ 'here' | translate }}</span></h2>
				</div>
			</div>
			<div class="col-md-6" *ngIf="this.clienttype == 0 && !this.userLogging">
				
			</div> -->
<div class="clearfix"></div>


												</ng-container>
<!-- aqui -->
<ul class="c-order list-unstyled" *ngIf="this.userLogging && this.cart && this.cart.length>0" style="margin-top: 20px;">
	<!-- <li class="row  " style="line-height: 10px;">

		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>

		<div class="col-lg-6 col-md-6 col-xs-12 c-border-top  " >

		</div>
	</li> -->


	<!-- [style]="this.clienttype==1 ? 'display:none;' : ''" -->
	<li class="row c-options c-margin-b-15" [style]="this.clienttype==1 ? 'display:none;' : ''">
<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
<div class="col-lg-6 col-md-6">
<label class="c-font-16">{{ 'discountcuppon' | translate }}</label>
<div class="input-group c-square">
<input #voucherCode id="voucherCode" class="form-control c-square c-theme valRequired valIgnore" type="text">
<span class="input-group-btn">
<button class="btn c-btn-grey-3 c-bg-black  " style="height: 35px;border-radius:0px" type="button" (click)="addVoucher(voucherCode.value)">{{ 'apply' | translate }}</button>
</span>
</div>
<div class="c-font-12 c-font-bold c-font-red">{{  this.voucherText | translate }}</div>
</div>
</li>
<li   class="row">

<div class="voucherWrapper col-12 pad0" [hidden]="!this.vouchersArr || this.vouchersArr.length==0">
<div class="voucherCart hidden"></div>
<div class="col-lg-6 col-md-6 hidden-xs   hidden-sm d-none  float-left">&nbsp;</div>
<div class="col-lg-6 col-xs-6 col-sm-6 col-md-6">
<p class="c-font-14 c-font-bold">{{ 'cuppons' | translate }}</p>
</div>

<div class="clearfix"></div>

<div class="col-lg-6 col-md-6 hidden-xs   hidden-sm d-none  float-left">&nbsp;</div>
<div class="col-lg-6 col-xs-12 col-sm-12 col-md-6">
<div class="voucherContainner">

<ng-container *ngIf="this.vouchersArr">
<ng-container *ngFor="let voucher of this.vouchersArr; let i=index;">
<div *ngIf="this.vouchersArr[i].accumulate==0" id="voucher_discount_msg"><font size="1">{{ 'Voucher_Discount_Promotion' | translate }} </font></div>

<div class="vouchWrap float-left pad0 col-xs-12">
<div class="row">
<div class="col-lg-6 col-xs-6 col-sm-6 col-md-6 float-left c-font-14 noPadding"><p class="">{{ voucher.code }}</p></div>
<div class="col-md-6 col-xs-6 col-sm-6 c-font-14 "><p class="">{{ voucher.total }} {{ voucher.symbol }}
<button type="button" class="close pull-right" aria-label="Close" (click)="removeVoucher(i, voucher.idvoucher )"><span aria-hidden="true">&times;</span></button>
</p> </div>
</div>  </div>
</ng-container >
</ng-container >

</div>
</div>
</div>
</li>
<li class="row c-options c-margin-b-30 c-margin-t-15" *ngIf="this.showpoints">
	<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
	<div class="col-lg-6 col-md-6">
		<label class="labelpoints" *ngIf="this.qtdPoints == 1">
			{{('exchange_points_buy' | translate).replace(":1:", this.qtdPoints)}}
		</label>
		<label class="labelpoints" *ngIf="this.qtdPoints > 1">
			{{('exchange_points_buy_multiple' | translate).replace(":1:", this.qtdPoints)}}
		</label>
		<label class="labelpoints" *ngIf="this.qtdPoints == 0 || !this.qtdPoints">
			{{('no_points_yet_finish_buy' | translate).replace(":1:", this.qtdPoints)}}
		</label>
		<div class="input-group c-square">
			<input #pointsExchange id="pointsExchange" min="0" class="form-control c-square c-theme valRequired valIgnore" type="number" value="{{(this.userexchangepoints != 0 ? this.userexchangepoints : '' )}}">
			<span class="input-group-btn">
			<button class="btn c-btn-grey-3 c-bg-black  " style="height: 35px;border-radius:0px; color: #fff;" type="button" (click)="exchangePoints(pointsExchange.value)">{{ 'exchange' | translate }}</button>
			</span>
		</div>
		<div class="c-font-12 c-font-bold c-font-red" *ngIf="this.showMsgErrorPoints">{{  (this.msgErrorPoints | translate).replace(":1:", this.errorPointsLimit) }}</div>
	</div>
</li>


	<li class="row voucherWrapper" [hidden]=" true " >
		<div class="col-lg-4 hidden-md hidden-sm hidden-xs">&nbsp;</div>
		<div class=" col-lg-4 col-xs-6 col-sm-6 col-md-8 c-font-14">
			<p class="c-font-16 c-margin-b-5 c-theme-font">{{ 'discount' | translate }}</p>
		</div>
		<div class="col-md-4 col-xs-6 col-sm-6 c-font-16">
			<p class="c-font-bold c-font-14 c-margin-b-5 c-theme-font">
				<span *ngIf = "this.cartTotal" class="c-shipping-total cartVoucherDiscount c-theme-font">{{   this.cartTotal.discount    }}</span>€
			</p>
		</div>
	</li>


	<li class="row voucherWrapper" [hidden]=" !this.cartTotal.discountvoucher || this.cartTotal.discountvoucher<=0  " >
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5 c-theme-font">{{ 'discountvoucher' | translate }} {{ 'withvat' | translate }}</p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14 c-margin-b-5 c-theme-font">
				<span *ngIf = "this.cartTotal" class="c-shipping-total cartVoucherDiscount c-theme-font">{{   formatN(this.cartTotal.discountvoucher)    }}</span>€
			</p>
		</div>
		</div>
	</li>

	<li class="row   " *ngIf="this.clienttype==1">

		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>

		<div class="col-lg-6 col-md-6 col-xs-12   "  >
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5">{{ 'totalprod' | translate }} {{ 'novat' | translate }} </p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14 c-margin-b-5">

				<span *ngIf = "this.cartTotal" class="c-shipping-total cartSubtotal">{{ formatN( (this.cartTotal.sum) )  }}</span>€
			</p>
		</div>
		</div>
	</li>

	<li class="row voucherWrapper" [hidden]=" !this.cartTotal.discountpoints || this.cartTotal.discountpoints<=0  " >
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5">{{ 'discountpoints' | translate }} <br> <span class="c-font-12">({{this.userexchangepoints}} {{'points' | translate}} )</span></p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14 c-margin-b-5">
				<span *ngIf = "this.cartTotal" class="c-shipping-total cartVoucherDiscount">{{   formatN(this.cartTotal.discountpoints)    }}</span>€
			</p>
		</div>
		</div>
	</li>

	<li class="row   " *ngIf="this.clienttype!=1">

		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>

		<div class="col-lg-6 col-md-6 col-xs-12   "  >
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5">{{ 'subtotal' | translate }}   </p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14 c-margin-b-5">

				<span *ngIf = "this.cartTotal" class="c-shipping-total cartSubtotal">{{ formatN( (this.cartTotal.total-this.shippingTotal) )  }}</span>€
			</p>
		</div>
		</div>
	</li>

	<li class="row discountClient" [hidden]="this.cartTotal && this.cartTotal.discount<=0  " >
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5 c-theme-font">{{ 'discountclient' | translate }}</p>
		</div>
		<div class="col-md-6 col-xs-4 col-sm-6 c-font-16">
			<p class="c-font-bold c-font-14 c-margin-b-5 c-theme-font">
				<span class="c-shipping-total cartDiscount c-theme-font">{{   this.cartTotal.discountTxt    }}</span>€
			</p>
		</div>
		</div>
	</li>

	<ng-container *ngIf="this.taxprocess && this.taxprocess != 0">
		<li class="row">
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
			<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
				<p class="c-font-16 c-margin-b-5 helveticaltsd">{{ 'taxprocess' | translate }} </p>
			</div>
			<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
				<p class="c-font-16 c-margin-b-5 c-font-bold">
					<span class="c-shipping-total shippingAddStart">{{ this.taxprocess.toFixed(2).toString().replace(".", ",")   }}</span>€
				</p>
			</div>
		</div>
		</li>
	</ng-container>

	<ng-container *ngIf="this.shippingTotal &&   this.shippingTotal>0">
	<li class="row  "   >
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-16  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5 helveticaltsd">{{ 'delivery' | translate }} <span *ngIf="this.clienttype==1" class='c-font-14'> {{ 'novat' | translate }}</span> </p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-16 c-margin-b-5 c-font-bold">
				<span *ngIf = "this.shippingTotal" class="c-shipping-total shippingAddStart">{{ this.shippingTotal.toFixed(2).toString().replace(".", ",")   }}</span>€
			</p>
		</div>
		</div>
	</li>
	</ng-container>

	<ng-container *ngIf="(!this.shippingTotal ||  this.shippingTotal==0)">
	<li class="row  "   >
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5 helveticaltsd">{{ 'delivery' | translate }} <span class='c-font-14'>  </span> </p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-12 c-margin-b-5 c-font-bold">
				<span *ngIf="this.selectShippingcompany"  class="c-shipping-total shippingAddStart">{{ 'freedelivery' | translate  }}</span>
				<span *ngIf="!this.selectShippingcompany"  class="c-shipping-total shippingAddStart">{{ 'select_delivery_country' | translate  }}</span>
			</p>
		</div>
		</div>
	</li>
	</ng-container>






	<li class="row" *ngIf="this.clienttype==1">
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-16 c-margin-b-5">{{ 'subtotal' | translate }} {{ 'novat' | translate }}</p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14 c-margin-b-5">
				<span class="c-shipping-total cartSubtotal">{{   this.cartTotal.withoutvatTxt    }}</span>€
			</p>
		</div>
		</div>
	</li>
	<li class="row" *ngIf="this.clienttype==1">
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-14  "  style="text-align:right">
			<p class="c-font-14 helveticaltsd">{{ 'vat' | translate }}</p>
		</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-14">
				<span class="c-shipping-total cartTotalVatCheck">{{   this.cartTotal.vatTxt    }}</span>€
			</p>
		</div>
		</div>
	</li>
	<li class="row c-margin-b-15 ">
		<div class="col-lg-6 col-md-6 hidden-sm hidden-xs">&nbsp;</div>
		<div class="col-lg-6 col-md-6 col-xs-12">
		<div class=" col-lg-8 col-xs-8 col-sm-8 col-md-8 c-font-16  "  style="text-align:right">
												<p *ngIf=" this.translate.currentLang!='fr'" class="c-font-24">{{ 'total' | translate }} <span class="c-font-14">{{ 'withvat' | translate }}</span></p>
												<p *ngIf=" this.translate.currentLang=='fr'" class="c-font-24">{{ 'totalcheck' | translate }}</p>
											</div>
		<div class="col-md-4 col-xs-4 col-sm-4 c-font-16  " style="text-align:right">
			<p class="c-font-bold c-font-24">
				<span class="c-shipping-total cartTotalCheck">{{   this.cartTotal.totalTxt    }}</span>€
			</p>
		</div>
		</div>
	</li>


	<ng-container *ngIf="  this.shippingfeefail>0">

		<li class="row  "   >
			<div class="col-lg-12 col-md-12 col-xs-12">
		<div class="col-md-12 col-xs-12 col-sm-12 c-font-14   " style="text-align:right">
			<p class="c-font-bold  " style="color:#eb5d68 ">
				<span class="c-shipping-total cartTotalCheck">{{   ('missingfreeshipping' | translate).replace(":1:", this.numberformat(this.shippingfeefail)+"€")    }}</span>
			</p>
		</div>
		</div>
		</li>
	</ng-container>

	<li class="row">
		<div class="col-lg-12 col-md-12 col-xs-12">
			<div class="col-md-12 col-xs-12 col-sm-12 c-font-14   " style="text-align:right" *ngIf="this.showpoints">

				<ng-container *ngIf="this.salesPoints && this.salesPoints >0">
					<ng-container *ngIf="this.salesPoints>1">
						<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{   ('finish_buy_win_points_multiple' | translate).replace(":1:", this.salesPoints) }}</span>
					</ng-container>
					<ng-container *ngIf="this.salesPoints==1">
						<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{('finish_buy_win_points' | translate).replace(":1:", this.salesPoints)}}</span>
					</ng-container>
				</ng-container>

				<!-- <ng-container *ngIf="this.ac_points.length >0; else singlepointbuy">
					<ng-container *ngFor="let point of this.ac_points">
						<div *ngIf="point.idpointtype == 3">
							<ng-container *ngIf="point.points>1">
								<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{   ('finish_buy_win_points_multiple' | translate).replace(":1:", point.points) }}</span>
							</ng-container>
							<ng-container *ngIf="point.points==1">
								<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{('finish_buy_win_points' | translate).replace(":1:", point.points)}}</span>
							</ng-container>
						</div>
					</ng-container>
				</ng-container>
				<ng-template #singlepointbuy>
					<div *ngIf="this.ac_points.idpointtype == 3">
						<ng-container *ngIf="point.points>1">
							<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{('finish_buy_win_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
						</ng-container>
						<ng-container *ngIf="point.points==1">
							<span class="c-shipping-total cartTotalCheck c-font-bold" style="color:#d48e0c;text-align:right ">{{('finish_buy_win_points' | translate).replace(":1:", this.ac_points.points)}}</span>
						</ng-container>
					</div>
				</ng-template> -->
			</div>
		</div>
	</li>


								<li class="row  ">
		<div role="group" class="form-group col-md-12">
												<div class="col-md-5 noPadding">&nbsp;</div>

			<div class="clearfix"></div>
		</div>
	</li>
</ul>							

										<div class="col-md-7 col-lg-7  col-xs-7c-font-16 c-font-bold c-font-red c-margin-t-10 pull-left limitMsg" limit="0"  wfd-invisible="true">
											<span *ngIf="this.cartTotal && userInfo && this.companyInfo && this.minbuy>this.cartTotal.withoutvat  && userInfo.withminlimit=='1'">{{ 'minforbuy' | translate }} <span class="limitTxt">{{ this.companyInfo.mintotal.toString().replace(".", ",") }}</span>€ {{ 'novat' | translate }}<br></span>



										</div>

						<button *ngIf="this.userLogging" class="btn pull-right 1 c-theme-btn c-btn-square c-btn-uppercase c-btn-bold buyButton col-xs-12 mt-20" (click)="buy()" type="button">{{ 'finishorder' | translate }}</button>
									</div>

				</div>














































            </div>
          </div>


<div id="loading"   [style]="this.hiddenLoad ? 'opacity: 0.9; display:none' : 'opacity: 0.9;'">
	<div class="mybox" id="loading-image">
		<div class="loada" id="loadingGeral"  ></div>
		<p class="noMargin">&nbsp;</p>
		<h3 class="title_h3 " id="txtLoad"></h3>
	</div>
</div>


<modal #ModalSignin  id="signin" class="modalRemoveFooter">
	<modal-header></modal-header>
	<modal-content>
		<div class="row mt-3">
			<div class="col-sm-12"><h1 style="white-space: pre-line">{{ 'checkout_signin_title' | translate }}</h1></div>
			<form [formGroup]="signinForm" (ngSubmit)="onSubmitSignin()" style="margin-top: 65px;">
				<div class="form-group col-sm-12">
					<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
					<input type="email" [ngClass]="{ 'is-invalid': submittedSign && fSign.email.errors }" (change)="changeEmailClear($event)" formControlName="email" id="email" class="form-control c-square valRequired valEmail" placeholder="{{ 'email' | translate }}" />
					<div *ngIf="this.submittedSign && fSign.email.errors" class="invalid-feedback">
					   <div *ngIf="fSign.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
					   <div *ngIf="fSign.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
					</div>
				</div>	
				<div class="form-group col-sm-12">
					<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
					<input type="password" id="password" [ngClass]="{ 'is-invalid': submittedSign && fSign.password.errors }" formControlName="password" class="form-control c-square valRequired"  placeholder="{{ 'password' | translate }}"/>
					<div *ngIf="this.submittedSign && fSign.password.errors" class="invalid-feedback">
						<div *ngIf="fSign.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
						<div *ngIf="fSign.password.errors.minlength">{{ 'min6char' | translate }}</div>
					</div>
				</div>	
				<div class=" col-md-6 col-xs-6"></div>	
				<div class=" col-md-6 col-xs-6 text-right float-left">
					<a (click)="openModalRecover();" href="javascript:void(0)" class="c-btn-forgot c-font-12 helveticaltsd "> {{ 'forgot_your_password' | translate }}</a>
				</div>
				<div class="clearfix"></div>
				<div style="margin-bottom: 25px;" class="form-group col-sm-12">
					<div class="col-md-12 row  ">
						<button type="submit" class="btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup c-font-14 helveticaltsd "><span>{{ 'login' | translate }}</span></button>
					 </div>
				</div>
			</form>
		</div>
	</modal-content>
</modal>

<modal #ModalPasswordCh  id="lostPass2" class="modalRemoveFooter">
    <modal-header></modal-header>
    <modal-content>
			<div class=" row">
				<div class="  col-md-12 col-xs-12 col-sm-12">
			<span class="title_h3 c-font-24 c-font-sbold full-width">{{ 'recover_password' | translate }}.</span>
			<div class="full-width"><p class="full-width">{{ 'email_to_retrieve_password' | translate }}</p></div>
			<div class="form-group full-width">
				<input type="email" #emailrecoverPassCh class="form-control  border-grey input-lg c-square valRequired valEmail" name="email" placeholder="{{ 'write_your_email' | translate }}">
			</div>
			<div class="form-group full-width">
				<button type="button" (click)="recoverPassch()" class="pull-right  btn c-btn-black c-btn-slim  c-btn-square c-btn-signup">{{ 'send' | translate }}</button>
			</div>
				</div>
			</div>
    </modal-content>

    <modal-footer>



    </modal-footer>
</modal>


<modal #pickupDetails  id="pickupDetails" class="modalRemoveFooter">
	<modal-header></modal-header>
	<modal-content>
		<div class="row">
			<div class="col-md-12 col-xs-12 col-sm-12">
				<div class="pickup-details" *ngIf="this.pickup_selected">
					<div class="pickup-info">
						<b><span class="name c-font-14">{{pickup_selected.name}}</span></b><br>
						<span class="address">{{pickup_selected.address}}</span>
					</div>
					<div class="horary">
						<p class="horary_title">{{'office_hours' | translate }}:</p>
						<ng-container *ngIf="pickup_selected.morningOpenHour2 != '0000' || pickup_selected.afterNoonOpenHour2 != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'monday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHour2 && pickup_selected.morningOpenHour2 != '0000' && pickup_selected.morningCloseHour2 && pickup_selected.morningCloseHour2 !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHour2)}} - {{getHour(pickup_selected.morningCloseHour2)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHour2 && pickup_selected.afterNoonOpenHour2 != '0000' && pickup_selected.afterNoonCloseHour2 && pickup_selected.afterNoonCloseHour2 != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHour2)}} - {{getHour(pickup_selected.afterNoonCloseHour2)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHour3 != '0000' || pickup_selected.afterNoonOpenHour3 != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'tuesday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHour3 && pickup_selected.morningOpenHour3 != '0000' && pickup_selected.morningCloseHour3 && pickup_selected.morningCloseHour3 !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHour3)}} - {{getHour(pickup_selected.morningCloseHour3)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHour3 && pickup_selected.afterNoonOpenHour3 != '0000' && pickup_selected.afterNoonCloseHour3 && pickup_selected.afterNoonCloseHour3 != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHour3)}} - {{getHour(pickup_selected.afterNoonCloseHour3)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHour4 != '0000' || pickup_selected.afterNoonOpenHour4 != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'wednesday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHour4 && pickup_selected.morningOpenHour4 != '0000' && pickup_selected.morningCloseHour4 && pickup_selected.morningCloseHour4 !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHour4)}} - {{getHour(pickup_selected.morningCloseHour4)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHour4 && pickup_selected.afterNoonOpenHour4 != '0000' && pickup_selected.afterNoonCloseHour4 && pickup_selected.afterNoonCloseHour4 != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHour4)}} - {{getHour(pickup_selected.afterNoonCloseHour4)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHour5 != '0000' || pickup_selected.afterNoonOpenHour5 != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'thursday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHour5 && pickup_selected.morningOpenHour5 != '0000' && pickup_selected.morningCloseHour5 && pickup_selected.morningCloseHour5 !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHour5)}} - {{getHour(pickup_selected.morningCloseHour5)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHour5 && pickup_selected.afterNoonOpenHour5 != '0000' && pickup_selected.afterNoonCloseHour5 && pickup_selected.afterNoonCloseHour5 != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHour5)}} - {{getHour(pickup_selected.afterNoonCloseHour5)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHour6 != '0000' || pickup_selected.afterNoonOpenHour6 != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'friday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHour6 && pickup_selected.morningOpenHour6 != '0000' && pickup_selected.morningCloseHour6 && pickup_selected.morningCloseHour6 !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHour6)}} - {{getHour(pickup_selected.morningCloseHour6)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHour6 && pickup_selected.afterNoonOpenHour6 != '0000' && pickup_selected.afterNoonCloseHour6 && pickup_selected.afterNoonCloseHour6 != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHour6)}} - {{getHour(pickup_selected.afterNoonCloseHour6)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHourS != '0000' || pickup_selected.afterNoonOpenHourS != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'saturday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHourS && pickup_selected.morningOpenHourS != '0000' && pickup_selected.morningCloseHourS && pickup_selected.morningCloseHourS !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHourS)}} - {{getHour(pickup_selected.morningCloseHourS)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHourS && pickup_selected.afterNoonOpenHourS != '0000' && pickup_selected.afterNoonCloseHourS && pickup_selected.afterNoonCloseHourS != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHourS)}} - {{getHour(pickup_selected.afterNoonCloseHourS)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="pickup_selected.morningOpenHourD != '0000' || pickup_selected.afterNoonOpenHourD != '0000'">
							<div class="time-container">
								<div class="week-day">{{ 'sunday' | translate }}</div>
								<div class="hour">
									<ng-container *ngIf="pickup_selected.morningOpenHourD && pickup_selected.morningOpenHourD != '0000' && pickup_selected.morningCloseHourD && pickup_selected.morningCloseHourD !='0000'">
										<span>{{getHour(pickup_selected.morningOpenHourD)}} - {{getHour(pickup_selected.morningCloseHourD)}}</span>
									</ng-container>
									<ng-container *ngIf="pickup_selected.afterNoonOpenHourD && pickup_selected.afterNoonOpenHourD != '0000' && pickup_selected.afterNoonCloseHourD && pickup_selected.afterNoonCloseHourD != '0000'">
										<span>{{getHour(pickup_selected.afterNoonOpenHourD)}} - {{getHour(pickup_selected.afterNoonCloseHourD)}}</span>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</modal-content>
</modal>