import { Component, OnInit , ViewChild, Renderer2, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2'
 import { SharedService } from '../shared.service';
import { HostListener } from '@angular/core';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { Angulartics2 } from 'angulartics2';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UrlpipePipe } from '../urlpipe.pipe';
import { DOCUMENT, KeyValue, LowerCasePipe } from '@angular/common';
import { SeoService } from '../seo.service';
import { OwlOptions  } from 'ngx-owl-carousel-o';
import { CheckwebpService } from '../checkwebp.service';
import { Location } from '@angular/common';
import { PlyrComponent } from 'ngx-plyr';
import { TitlechangeService } from '../titlechange.service';
import { SessionService } from '../session.service';
import { Subscription } from 'rxjs';
import { OpenmodalloginService } from '../openmodallogin.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { WishlistService } from '../wishlist.service';
import { ModalManager } from 'ngb-modal';
import { SharedPointsService } from '../shared-points.service';
declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild('owlElement') owlElement;
  @ViewChild('owlElement1') owlElement1;
  @ViewChild('ModalReview') ModalReview;
  @ViewChild(PlyrComponent)
  player: PlyrComponent;
  private _subscription: Subscription[];
  typeclient:any;
  showFlag: boolean = false;
    selectedImageIndex: number = -1;
    imageObject:Array<object> = [];
    currentIndex:any;
  breadcrumbs:Array<Object> = [];
  iditem:any;idcolor:any;idsize:any;
  countParams:any;
  item:any;
  qtd:any;
  userLogging: Boolean= false;
  qtdInitial:any;
  subscribe:any;
  reviewDetail: any;

  linkCode:any;
  ac_points:any;
  showsharelink: boolean = false;
  gap: any;

  carouselOptionsDetail: OwlOptions = {
      loop:false,
      margin:10,
      autoplay: false,
      items: 1,
      nav:true,
      responsive:{
        0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
      },
      navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
  }
  carouselOptionsDetail2: OwlOptions = {
    loop:false,
    margin:10,
    autoplay: false,
    items: 1,
    nav:true,
    responsive:{
      0: {
      items: 2
    },
    400: {
      items: 3
    },
    740: {
      items: 3
    },
    940: {
      items: 4
    }
    },
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
}
  calcDivImage:number;
  keyImageSel:any;
  public relateditemarr: any = [];
  deviceDesktop:any;
  browserwebp:any;
    breadcumbschange:any=false;
    entryOne:any;

  pval:any;

  defaultImage:any;
  sideimges = [1,2,3,4];
  loadingOtherprods = [1,2,3,4,5,6]

  scriptProduct: any;

  reviews: any[] = [];
  totalreviews = 3;

  reviewForm:FormGroup;
  submittedReview = false;
  hidecomment: boolean = false;
  loadAll: string = '0';
  average_rating: any = 0;

  filelist: any[] = [];
  images_64: any[] = [];
  showimageReviews: boolean = false;
  imageReviews:any[] = [];
  imageReviewIndex: number = -1;

  keyImageSelModal:any

  carouselOptions: OwlOptions  = {
    nav : false,
    dots: false,
    autoplay: true,
    loop: false,
    rewind: true,
    items:1,
    autoWidth:true,
    autoHeight:true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
  }

  modalReviewRef:any

  purchase_idcolor: any;
  purchase_idsize: any;

  salesPoints:any;

  constructor(public openmodalloginService:OpenmodalloginService,public sessionService: SessionService,public titlechangeService:TitlechangeService, public location: Location, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,private seoService: SeoService, private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe, public localize: LocalizeRouterService, private angulartics2: Angulartics2, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService,  public apiService: ApiService, public translate: TranslateService, private route: ActivatedRoute, private router: Router, public sharedService: SharedService,private formBuilder: FormBuilder,public wishlistService:WishlistService, public modalService: ModalManager, private sharedpoints: SharedPointsService, private _renderer2: Renderer2,@Inject(DOCUMENT) private _document: Document) {

    let self = this;
    window.addEventListener('storage', function(e) {
      self.userLogging = localstorageService.get("userLogging");
    })
    
    this._subscription = [];
    this.browserwebp = this.checkwebp.canUseWebP();
    if(this.browserwebp){
      this.defaultImage = "assets/images/imgdefault.webp";
    }else{
      this.defaultImage = "assets/images/imgdefault.jpg";
    }

    let sub50 = this.sessionService.getsessionEvent().subscribe((obj)=>{
       this.userLogging = obj[0];
    });
    this._subscription.push(sub50);

    if(window.innerWidth<992){
      this.deviceDesktop=false;
    }else{
      this.deviceDesktop=true;
    }
    this.entryOne=false;
    //this.iditem = this.route.snapshot.paramMap.get('id');
    this.idcolor = this.route.snapshot.paramMap.get('color');
    this.idsize = this.route.snapshot.paramMap.get('size');

    if(this.route.snapshot.paramMap.get('description')){
      let splitS = this.route.snapshot.paramMap.get('description').split("-") ;
        this.iditem = parseInt(splitS[splitS.length-1]);
    }

    if(this.route.snapshot.queryParamMap.get('sharelink')){
      this.sendClickEvent(this.route.snapshot.queryParamMap.get('sharelink'));
    }

    if(!this.iditem || this.iditem==0){
      this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
    }

    this.qtd=1;
  /*  this.breadcrumbsLoad(breadcrumbs);*/
    this.route.params.subscribe(params => {
      let a:any = false;

      let splitS = params.description.split("-") ;
      let id = parseInt(splitS[splitS.length-1]);

      if(this.iditem==id  ){
        a=true;
      }
      this.iditem = id;
      if(this.iditem!=id){
        this.idcolor = null;
        this.idsize = null;
      }


      this.breadcumbschange=true;

      this.breadcrumbs.pop();

      if(this.countParams==Object.keys(params).length && !a){this.entryOne=false;
        this.ngOnInit();
      }
      this.countParams = Object.keys(params).length;

    });

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      if(this.item && this.item.langs && this.item.langs[lang.lang].description){
        this.route.snapshot.url[1].path = this.lowercase.transform(this.urlpipePipe.transform(this.item.langs[lang.lang].description)+"-"+this.item.iditem) ;
      }

      if(this.item &&  this.item.langs && typeof this.item.langs[lang.lang]!=="undefined"){
        this.seoService.updateCanonicalUrl( this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/product/'+this.lowercase.transform(this.urlpipePipe.transform(this.item.langs[lang.lang].description))+ '-'+ this.item.iditem );
      }else{
        this.seoService.removeTag("rel=canonical");
      }
      this.apiService.GET_TAGS_META.forEach(key => {
        this.metaService.removeTag('name="' + key + '"');
      });
      this.seoService.clearAlternateUrl();

      this.metatags(lang.lang);
      this.getGap(lang.lang);

      if(this.item){
        let desc:any;
        if(this.item.langs[this.translate.currentLang].description){
          desc = this.item.langs[this.translate.currentLang].description;
        }
        else{
          desc = this.item.description;
        }
        let url = this.apiService.PHP_API_URL_DEFAULT +this.router.url;
        let image = [];
        if(this.item.itemwithattr == 1){
          image = this.item.colors[this.idcolor][this.idsize].images;
        }
        else{
          image = this.item.images;
        }
        let brand = (this.item.brandName) ? this.item.brandName : '';
  
        let pval:any = "";
        if(this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].priceFinal) { pval = this.item.colors[this.idcolor][this.idsize].priceFinal; }else{ pval=this.item.priceFinal; }
  
        let color = "";
        if(this.item.itemwithattr==1){
          color =this.item.colors[this.idcolor][this.idsize].name;
        }

        let description = this.item.langs[this.translate.currentLang].composition;
        if(!description || description == ""){
          description = this.item.langs[this.translate.currentLang].compositionmaterial;
        }
        if(description){
          description = $("<div/>").html(description).text();
          description = description.replace(/[\r\n]+/g," ");
          description = description.replace(/['"]+/g, '')
        }
        else{
          description = desc;
        }

        this.createStructuredData(this.iditem, desc, url, description, image, pval, brand, color, this.item.avgrating, this.item.totalreviews, this.item.examplereview);
      }
    });

    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {

    var pathArray = window.location.pathname.split('/');
    let key = 2;
    if(pathArray[2]=="pt" || pathArray[2]=="en" || pathArray[2]=="es" || pathArray[2]=="fr"){
      key = 3;
    }

    if(event.target.origin==this.apiService.PHP_API_URL_DEFAULT || event.target.origin==(this.apiService.PHP_API_URL_DEFAULT).replace("/new", "") || event.target.origin=="http://localhost:4300"){
      if(pathArray[key] && (pathArray[key]=="mostrar" || pathArray[key]=="spectacle" || pathArray[key]=="mostrar" || pathArray[key]=="show")){
        this.localstorageService.set("detailBack", 1);
      }
    }
  }

  showLightbox(index) {
      if(this.item.itemwithattr==1){
        let a =  this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[index].photo
        var elementPos = this.imageObject.findIndex(function(obj:any){return obj.image == a})
        if(elementPos && !isNaN(elementPos) && elementPos != -1){
          this.selectedImageIndex = elementPos;
        }
        else{
          this.selectedImageIndex = 0;
        }
      }
      else{
        this.selectedImageIndex = index;
      }
      this.showFlag = true;
    }

    closeEventHandler() {
        this.showFlag = false;
        this.currentIndex = -1;
    }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      if(event.target.innerWidth<992){
        this.deviceDesktop = false;

      }else{
        this.deviceDesktop = true;
      }
    }

    plyrReady(){
      this.player.player.play()
    }

  breadcrumbsLoad(breadcrumbs:any){
    this.breadcrumbs = [];
    if(breadcrumbs){

    }else{

        /*this.translate.get( 'detail' ).subscribe(translations => {
          let obj:any = {} ;
          let obji:any = {} ;
          //obji.id = this.iditem;
          obj.url = [];
          //obj.url.push(obji);
          obj.description=translations;
          this.breadcrumbs.push(obj);
        });*/
        this.localstorageService.remove('show_breadscrumbs');
    }
  }





  cicleImageKey(op, attr){
      if(attr==1){
        if(op==0){
          if(this.keyImageSel==-2 && this.item && this.item.video_id){
            this.keyImageSel=this.item.colors[this.idcolor][this.idsize].images.length-1;
          }else
          if(this.keyImageSel>0){
            this.keyImageSel--;
          }
        }else{
          if(this.keyImageSel==this.item.colors[this.idcolor][this.idsize].images.length-1 && this.item && this.item.video_id){
            this.keyImageSel=-2;
          }else
          if(this.keyImageSel!=-2 && this.keyImageSel<this.item.colors[this.idcolor][this.idsize].images.length-1){
            this.keyImageSel++;
          }
        }
      }

      else
      if(attr==0){
        if(op==0){
          if(this.keyImageSel>0){
            this.keyImageSel--;
          }
        }else{
          if(this.keyImageSel<this.item.images.length-1){
            this.keyImageSel++;
          }
        }
      }

  }

  cicleImgModal(op, attr){
    if(attr==1){
      if(op==0){
        if(this.keyImageSelModal==-2 && this.item && this.item.video_id){
          this.keyImageSelModal=this.reviewDetail.images.length-1;
        }else
        if(this.keyImageSelModal>0){
          this.keyImageSelModal--;
        }
      }else{
        if(this.keyImageSelModal!=-2 && this.keyImageSelModal<this.reviewDetail.images.length-1){
          this.keyImageSelModal++;
        }
      }
    }
    else{
      if(op==0){
        if(this.keyImageSelModal>0){
          this.keyImageSelModal--;
        }
      }else{
        if(this.keyImageSelModal<this.reviewDetail.images.length-1){
          this.keyImageSelModal++;
        }
      }
    }
  }


  formatDateLocal(date:any){
    try {
      let dates = new Date(date);
      return dates.toLocaleDateString();
    } catch (error) {
      return date;
    }
  }

  ngOnInit(): void {
    this.keyImageSelModal = 0;
    this.initFormReview();
    this.getItem();
    this.getProductReviews(this.translate.currentLang,this.loadAll);
    this.getPoints();
    this.getGap(this.translate.currentLang);
    // this.getRelated();
    if(this.localstorageService.get('show_breadscrumbs') && this.route.snapshot.paramMap.get('breadcrumbs')!=""){
      this.breadcrumbs = this.localstorageService.get('show_breadscrumbs');
      this.breadcumbschange=false;
      this.localstorageService.remove('show_breadscrumbs');
    }else{

      let createBreadcrumbsP = this.localstorageService.get('createBreadcrumbsAll');

      if(createBreadcrumbsP && createBreadcrumbsP!=null){
        this.breadcrumbs = [];
       if(createBreadcrumbsP){
           for (let index = 0; index < createBreadcrumbsP.length; index++) {

             this.breadcrumbs.push({ "url": (typeof createBreadcrumbsP[index]["url"][this.translate.currentLang]!="undefined" ? createBreadcrumbsP[index]["url"][this.translate.currentLang] : createBreadcrumbsP[index]["url"][0] ), "description":(typeof createBreadcrumbsP[index]["description"][this.translate.currentLang]!="undefined" ? createBreadcrumbsP[index]["description"][this.translate.currentLang] : createBreadcrumbsP[index]["description"][0] ) });
           }

       }

      if(this.item){
      /*  let objBread:any = {};

          objBread.description = (this.translate.currentLang && this.item && typeof this.item.langs!="undefined" ) ? this.item.langs[this.translate.currentLang].description : this.item.description;
objBread.url =  "/product/"+this.lowercase.transform(this.urlpipePipe.transform(objBread.description)+"-"+this.item.iditem);
  			this.breadcrumbs.push(objBread);*/
      }
    }else{
      this.breadcumbschange=false;
			this.breadcrumbsLoad(false);
    }
		}
  }

  get f() { return this.reviewForm.controls; }
  initFormReview() {
    this.reviewForm = this.formBuilder.group({
      rating: new FormControl('',[  Validators.required]),
      title: new FormControl('',[  Validators.required]),
      comment: new FormControl('',[  Validators.required]),
    })
  }

  getGap(lang:String){
    this.apiService.getGap(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.gap = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  getRelated(){
    this.apiService.getrelateditem(this.iditem).subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){ 
         this.relateditemarr = obj.info;
       }else{
         this.relateditemarr = [];
       }
    });
  }


  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle  );this.titlechangeService.send();
  }

  metatags(lang){
    if(this.entryOne==true){
      return;
    }
    this.entryOne=true;
    this.apiService.getMetatags(this.iditem,1,lang).subscribe((obj: any)=>{
      this.entryOne=false;
	  let extraString = ""
      if(typeof obj!=="undefined" && obj.code==200){
		  if(this.iditem) {extraString += "i"+this.iditem;}
		  if(this.idcolor) {extraString += "cl"+this.idcolor;}
		  if(this.idsize) {extraString += "sz"+this.idsize;}


        if(obj.title && obj.title.content){
          this.setTitle( obj.title.content);
        }
		else if(this.item && this.item.langs) {
			let title = this.item.langs[this.translate.currentLang].description + " " + extraString;
			this.setTitle( title);
		}
    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="'+key+'"');
    });
        if(obj.info.length==0){
          return;
        }


			Object.keys(obj.info).forEach(key => {

			  if(obj.info[key].name=="description" && this.item && this.item.langs) {
				obj.info[key].content =  this.item.langs[this.translate.currentLang].description + " - " + obj.info[key].content + " " + extraString + " " ;
			  }
        else if(obj.info[key].name=="og:description"){
          let content = obj.info[key].content 
          const temp = document.createElement('div');
          temp.innerHTML = content;
          let finaltext = temp.textContent || temp.innerText;
          finaltext = finaltext.replace(/(\r\n|\n|\r)/gm, " ");;
          obj.info[key].content = finaltext;
        }
        let txt = document.createElement("textarea");
        txt.innerHTML = obj.info[key].content;
        txt.value = txt.value.replace("&nbsp;", " ");
        
        obj.info[key].content=txt.value;

			});
			this.metaService.addTags(obj.info);

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }



  getItem(){
    this.apiService.getItem(this.iditem).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.typeclient = obj.type;
        this.metatags(this.translate.currentLang);
        if(obj.info.length==0){
          this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
        }
        let desc:any;
        let brand:any = "";
        let color:any = "";
        let cat:any = "";
        if(this.translate.currentLang){
          desc = obj.info.langs[this.translate.currentLang].description;
        }else{
          desc = obj.info.description;
        }
        if(this.route.snapshot.url[1].path!=this.lowercase.transform(this.urlpipePipe.transform(desc))+"-"+this.iditem){

          this.router.navigate( [this.localize.translateRoute('/product'), this.lowercase.transform(this.urlpipePipe.transform(desc))+"-"+this.iditem]   );
        }

        this.seoService.updateCanonicalUrl( this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/product/'+this.lowercase.transform(this.urlpipePipe.transform(desc))+ '-'+ this.iditem );


        if(this.translate.currentLang && obj.info.catlangs && obj.info.catlangs[this.translate.currentLang] && obj.info.catlangs[this.translate.currentLang].description) {
          cat = obj.info.catlangs[this.translate.currentLang].description;
        }

        brand = (obj.info.brandName) ? obj.info.brandName : '';

        if(obj.info.itemwithattr==1){
          if(!obj.info.colors || obj.info.colors.length==0){
            this.router.navigate([this.localize.translateRoute('/404')], {skipLocationChange: true} );return;
          }
          if(!this.idcolor){
            if(obj.info.colorpriority && obj.info.colorpriority in obj.info.colors){
              this.idcolor = obj.info.colorpriority;
            }else{
              this.idcolor = Object.keys(obj.info.colors)[0];
            }
          }else{
            if(typeof obj.info.colors[this.idcolor] == "undefined"){
              this.idcolor = Object.keys(obj.info.colors)[0];
            }
          }
          if(!this.idsize){
            this.idsize = Object.keys(obj.info.colors[this.idcolor])[0];
          }else{
            if(typeof obj.info.colors[this.idcolor][this.idsize] == "undefined"){
              this.idsize = Object.keys(obj.info.colors[this.idcolor])[0];
            }
          }
          if(!obj.info.colors[this.idcolor][this.idsize].qtdCart || obj.info.colors[this.idcolor][this.idsize].qtdCart==0){
            this.qtd=1;
          }else{
            this.qtd=Number(obj.info.colors[this.idcolor][this.idsize].qtdCart);
          }
          color = obj.info.colors[this.idcolor][this.idsize].name;
        }else{
          if(!obj.info.qtdCart || obj.info.qtdCart==0){
            this.qtd=1;
          }else{
            this.qtd=Number(obj.info.qtdCart);
          }
        }

        this.getProductAccPoints();
        this.keyImageSel = 0;
        this.qtdInitial = this.qtd;
        let pval:any = "";
			if(obj.info.itemwithattr==1 && obj.info.colors[this.idcolor][this.idsize].priceFinal) { pval = obj.info.colors[this.idcolor][this.idsize].priceFinal; }else{ pval=obj.info.priceFinal; }

        this.angulartics2GAEcommerce.ecAddProduct({
              'id': this.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("detail", {});

			this.angulartics2.eventTrack.next({
				action: 'detail',
				properties: {
          label: 'detail',
					content_name: desc,
					content_category: cat,
					value: pval,
					currency: "EUR",
          "event":"Detail",
          gtmCustom:{
            'ecommerce': {
              'purchase': undefined,
              'click': undefined,
              'add': undefined,
              'checkout': undefined,
              'impressions': undefined,
              'remove': undefined,
              'detail': {
                'actionField': {'list': 'Detail Product'},    // 'detail' actions have an optional list property.
                'products': [{
                  'id': this.iditem,
                  'name': desc,// Name or ID is required.
                  'price': pval,
                  'brand': brand,
                  'category': cat,
                  'variant': color
                 }]
               }
             }
          }
				}
			});

      this.item = obj.info;

      let url = this.apiService.PHP_API_URL_DEFAULT +this.router.url;
      let image = [];
      if(this.item.itemwithattr == 1){
        image = obj.info.colors[this.idcolor][this.idsize].images;
      }
      else{
        image = obj.info.images;
      }
      let description = obj.info.langs[this.translate.currentLang].composition;
      if(!description || description == ""){
        description = obj.info.langs[this.translate.currentLang].compositionmaterial;
      }
      if(description){
        description = $("<div/>").html(description).text();
        description = description.replace(/[\r\n]+/g," ");
        description = description.replace(/['"]+/g, '')
      }
      else{
        description = desc;
      }

      this.createStructuredData(this.iditem,desc,url,description,image,pval,brand,color, obj.info.avgrating, obj.info.totalreviews, obj.info.examplereview);

      this.imageObject = [];
    let objBread:any = {};
    objBread.description = (this.item.langs[this.translate.currentLang]) ? this.item.langs[this.translate.currentLang].description : this.item.description;
    objBread.url =  "/product/"+this.lowercase.transform(this.urlpipePipe.transform(objBread.description)+"-"+this.item.iditem);
      let createBreadcrumbsP = this.localstorageService.get('createBreadcrumbsAll');

      if(!(createBreadcrumbsP && createBreadcrumbsP!=null)){
objBread.description = this.translate.instant( 'detail' )+": "+objBread.description;
      }

      if(this.item.itemwithattr==1){

        let reordercolor = this.reordercolor(this.item.colors);
        reordercolor.forEach(color => {
          let reodersize = this.reordersize(color);
          reodersize.forEach((size:any) =>{
            size.images.forEach(image => {
              this.imageObject.push({image: this.apiService.imagesDir+image.photo});
            });
          })
        });
      }else{
        for (let index = 0; index < this.item.images.length; index++) {
           this.imageObject.push({image: this.apiService.imagesDir+this.item.images[index].photo});
        }
      }
      if(this.item.video_play>0){
        this.imageObject.push({video: this.item.video+"?mute=1", videoAutoPlay:true});
      }
			this.breadcrumbs.push(objBread);

      this.seoService.clearAlternateUrl();

        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/product/'+this.lowercase.transform(this.urlpipePipe.transform(this.item.langs['fr'].description)+"-"+this.item.iditem), 'x-default');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/product/'+this.lowercase.transform(this.urlpipePipe.transform(this.item.langs['pt'].description)+"-"+this.item.iditem), 'pt');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/product/'+this.lowercase.transform(this.urlpipePipe.transform(this.item.langs['en'].description)+"-"+this.item.iditem), 'en');
         this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/product/'+this.lowercase.transform(this.urlpipePipe.transform(this.item.langs['fr'].description)+"-"+this.item.iditem), 'fr');


      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  imgArr(item:any,idcolor:any){
    if(item.itemwithattr==1){
      if(idcolor){
        let size = Object.keys(item.colors[idcolor])[0];
          return item.colors[idcolor][size].images;
      }else{
        if(item.colorpriority && item.colorpriority in item.colors){
          let size = Object.keys(item.colors[item.colorpriority])[0];
           return item.colors[item.colorpriority][size].images;
        }else{
          let color = Object.keys(item.colors)[0];
          let size = Object.keys(item.colors[color])[0];
          return item.colors[color][size].images;
        }
      }
    }else{
      return item.images;
    }
  }


  getoneimg(item:any,idcolor:any){
    let imgs = this.imgArr(item, idcolor);
    if(this.browserwebp && imgs[0].webp){
      return this.apiService.imagesDir+imgs[0].webp;
    }
    return this.apiService.imagesDir+ imgs[0].photo;
  }



  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        if(product.colorpriority && product.colors[product.colorpriority] ){
        id_color =  product.colorpriority;
        }else{
          id_color = Object.keys(product.colors)[0];
        }
      }
      let id_size = idsize;
      if(!idsize){
        id_size = Object.keys(product.colors[id_color])[0];
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           },
           'view_item': {
            'currency': 'EUR', 'value':pval,    // 'detail'
            'items': [{
              'item_id': product.iditem,
              'item_name': desc,
              'price': pval,
              'item_brand': brand,
              'item_category': cat,
              'item_variant': color
             }]
           }
         }
      }
    }
  });

setTimeout(()=>{
    if(idsize){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return;
    }else
    if(idcolor){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return;
    }else{
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return;
    }
    }, 100);
  }

  showDiscount(){
    let item = this.item;
    if(item.typepromotion==1){
      return Math.floor( parseFloat(item.promotionvalue) );
    }
    if(item.itemwithattr==1){
      if(this.idcolor && this.idcolor in item.colors){
        if(this.idsize){
          return Math.floor(Math.round((100-(100*item.colors[this.idcolor][this.idsize].priceFinal/item.colors[this.idcolor][this.idsize].priceOriginal))* 100) / 100);
        }else{
          let key = Object.keys(item.colors[this.idcolor])[0];
          return Math.floor(Math.round((100-(100*item.colors[this.idcolor][key].priceFinal/item.colors[this.idcolor][key].priceOriginal))* 100) / 100);
        }
      }else
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = "0";
        if(this.idsize){
           key = this.idsize;
        }else{
           key = Object.keys(item.colors[item.colorpriority])[0];
        }
        return   Math.floor(Math.round((100-(100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal))* 100) / 100);
      }else{
        let color = Object.keys(item.colors)[0];
        let key = "0";
        if(this.idsize){
           key = this.idsize;
        }else{
           key = Object.keys(item.colors[color])[0];
        }
        return Math.floor(Math.round((100-(100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal))* 100) / 100);
      }
    }else{
      return Math.floor(Math.round((100-(100*item.priceFinal/item.priceOriginal*100))* 100) / 100);
    }
  }

  showDiscountRelated(item){
    if(item.typepromotion==1){
      return Math.floor( parseFloat(item.promotionvalue) );
    }
    else{
      return Math.floor(Math.round((100-(100*item.priceFinal/item.priceOriginal*100))* 100) / 100);
    }
  }

  showColorName(itm:any){
      let key = Object.keys(itm)[0];
      return itm[key].langs[this.translate.currentLang].name;
  }

  showbyColorName(){
      if(!this.item){
        return "";
      }
      let color = Object.keys(this.item.colors)[0];
      if(this.idcolor){
        color = this.idcolor;
      }
      if(!this.item.colors[color]){
          color = Object.keys(this.item.colors)[0];
      }
      let key = Object.keys(this.item.colors[color])[0];
      return this.item.colors[color][key].langs[this.translate.currentLang].name;
  }

  getImg(itm:any){
      let key = Object.keys(itm)[0];
      if(this.browserwebp && itm[key].images[0].webp){
        return this.apiService.imagesDir+itm[key].images[0].webp;
      }
      return this.apiService.imagesDir+ itm[key].images[0].photo;
  }

  getSize(itm:any){
    if(this.idsize && this.idsize in itm){
      return itm[this.idsize].number;
    }else{
      let key = Object.keys(itm)[0];
      return itm[key].number;
    }
  }


  reordersize(colors){
    let r = Object.values(colors);
    r.sort(function(a, b) {
        return parseInt(a["sizeorder"]) - parseInt(b["sizeorder"]);
    });

    return r;
  }

  reordercolor(colors){
    let r = Object.values(colors);
    r.sort(function(a,b){
      let key = Object.keys(a)[0];
      let key2 = Object.keys(b)[0];
      return parseInt(a[key]["order"]) - parseInt(b[key2]["order"]);
    })
    return r;
  }

  returnZero() {
    return 0
  }

  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    for (var i = 0; i < this._subscription.length; i++) {
       if(this._subscription[i]){
         this._subscription[i].unsubscribe();
       }
    }

    if(this.scriptProduct){
      this._renderer2.removeChild(this._document.body, this.scriptProduct);
    }
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }


  showPrice(item:any, op:any, idcolor:any, idsize:any){
    if(!item){
      return "";
    }

    if(op==1){

      if(item.itemwithattr==1){
        if(idcolor && idcolor in item.colors){
          if(idsize){
            return  item.colors[idcolor][idsize].priceOriginalTxt ;
          }else{
            let key = Object.keys(item.colors[idcolor])[0];
            return  item.colors[idcolor][key].priceOriginalTxt ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{

      if(item.itemwithattr==1){
        if(idcolor && idcolor in item.colors){
          if(idsize){
            return  item.colors[idcolor][idsize].priceFinalTxt ;
          }else{
            let key = Object.keys(item.colors[idcolor])[0];
            return  item.colors[idcolor][key].priceFinalTxt ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }
  }

  getStock(){
    if(!this.item){
      return "0";
    }
      let item = this.item;
      if(item.itemwithattr==1){
        if(this.idcolor && this.idcolor in item.colors){
          if(this.idsize){
            return  item.colors[this.idcolor][this.idsize].stock ;
          }else{
            let key = Object.keys(item.colors[this.idcolor])[0];
            return  item.colors[this.idcolor][key].stock ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(this.idsize){
             key = this.idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].stock;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(this.idsize){
             key = this.idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].stock;
        }
      }else{
        return item.stock;
      }
  }


  changeColor(color:any, size:any){
      this.idcolor=color;
      if(typeof size!=="undefined"){
        this.idsize=size;
      }else{
        this.idsize = Object.keys(this.item.colors[this.idcolor])[0];
      }

      if(!this.item.colors[this.idcolor][this.idsize].qtdCart || this.item.colors[this.idcolor][this.idsize].qtdCart==0){
        this.qtd=1;
      }else{
        this.qtd=Number(this.item.colors[this.idcolor][this.idsize].qtdCart);
      }
      this.keyImageSel = 0;
      // this.imageObject = [];
      this.selectedImageIndex = 0;
      this.getProductAccPoints();
      // if(this.item.itemwithattr==1){

      //   for (const element of this.cicle(this.item.colors[this.idcolor])) {
      //       this.imageObject.push({image: this.apiService.imagesDir+element.photo});
      //   }
      // }else{
      //   for (let index = 0; index < this.item.images.length; index++) {
      //      this.imageObject.push({image: this.apiService.imagesDir+this.item.images[index].photo});
      //   }
      // }
      // if(this.item.video_play>0){
      //   this.imageObject.push({video: this.item.video+"?mute=1&autoplay=1", videoAutoPlay:true});
      // }
  }


  changeSize(size:any){
      this.idsize=size;
      if(!this.item.colors[this.idcolor][this.idsize].qtdCart || this.item.colors[this.idcolor][this.idsize].qtdCart==0){
        this.qtd=1;
      }else{
        this.qtd=Number(this.item.colors[this.idcolor][this.idsize].qtdCart);
      }
  }

  cicle(color: any){
    let key = Object.keys(color)[0];
    return color[key].images;
  }

  cicleleng(color: any){
    let key = Object.keys(color)[0];
    return color[key].images.length;
  }

  buyProduct(){
    let arr:any=[];
    let obj: any = {};

    let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let arrsendlitics_inc = [];
    let arrsendlitics_incga4 = [];
    let arrsendlitics_desc = [];
    let arrsendlitics_descga4 = [];

    let valueInput = false;

    if(this.translate.currentLang && this.item.langs[this.translate.currentLang]){
      desc = this.item.langs[this.translate.currentLang].description;
    }else{
      desc = this.item.description;
    }
    if(this.translate.currentLang && this.item.catlangs && this.item.catlangs[this.translate.currentLang] && this.item.catlangs[this.translate.currentLang].description) {
      cat = this.item.catlangs[this.translate.currentLang].description;
    }
    brand = (this.item.brandName) ? this.item.brandName : '';


    obj.iditem = this.item.iditem;
    obj.idmanager_company = this.item.idmanager_company;
    obj.iditem_integration = this.item.iditem_integration;
    obj.quantitycomplete = 1;



    if(this.item && this.item.itemwithattr==1){



      let copy ;
      for (var [key_1, value_1] of Object.entries(this.item.colors)) {
        for (var [key_2, value_2] of Object.entries(this.item.colors[key_1])) {
          copy = Object.assign({}, obj);

          if((<HTMLInputElement>document.getElementById("qtd_stock_"+copy.iditem+"_"+key_1+"_"+key_2)).value==""){
            copy.quantity = 0;
            copy.quantitycomplete = 0;

            if(this.item.colors[key_1][key_2].qtdCart>0){
              if(this.item.colors[key_1][key_2].priceFinal) { this.pval = this.item.colors[key_1][key_2].priceFinal; }
              color = this.item.colors[key_1][key_2].name;
              arrsendlitics_desc.push({
                  'id': this.item.iditem,
                  'name': desc,
                  'price': this.pval,
                  'brand': brand,
                  'category': cat,
                  'variant': color,
                  'quantity': this.item.colors[key_1][key_2].qtdCart-copy.quantity
                 });
                 arrsendlitics_descga4.push({
                  'item_id': this.item.iditem,
                  'item_name': desc,
                  'price': this.pval,
                  'item_brand': brand,
                  'item_category': cat,
                  'item_variant': color,
                  'quantity': this.item.colors[key_1][key_2].qtdCart-copy.quantity
                 });
            }

          }else{
            valueInput = true;
            copy.quantity = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+copy.iditem+"_"+key_1+"_"+key_2)).value);
            copy.quantitycomplete = copy.quantity;
            if(this.item.colors[key_1][key_2].priceFinal) { this.pval = this.item.colors[key_1][key_2].priceFinal; }
            color = this.item.colors[key_1][key_2].name;

            if(this.item.colors[key_1][key_2].qtdCart>copy.quantity){
              arrsendlitics_desc.push({
                  'id': this.item.iditem,
                  'name': desc,
                  'price': this.pval,
                  'brand': brand,
                  'category': cat,
                  'variant': color,
                  'quantity': this.item.colors[key_1][key_2].qtdCart-copy.quantity
                 });
                 arrsendlitics_descga4.push({
                  'item_id': this.item.iditem,
                  'item_name': desc,
                  'price': this.pval,
                  'item_brand': brand,
                  'item_category': cat,
                  'item_variant': color,
                  'quantity': this.item.colors[key_1][key_2].qtdCart-copy.quantity
                 });
            }else if(this.item.colors[key_1][key_2].qtdCart<copy.quantity){
              arrsendlitics_inc.push({
                  'id': this.item.iditem,
                  'name': desc,
                  'price': this.pval,
                  'brand': brand,
                  'category': cat,
                  'variant': color,
                  'quantity': copy.quantity-this.item.colors[key_1][key_2].qtdCart
                 });
                 arrsendlitics_incga4.push({
                  'item_id': this.item.iditem,
                  'item_name': desc,
                  'price': this.pval,
                  'item_brand': brand,
                  'item_category': cat,
                  'item_variant': color,
                  'quantity': copy.quantity-this.item.colors[key_1][key_2].qtdCart
                 });
            }

          }

          copy.idcolor = key_1;
          copy.idsize = key_2;
          copy.fullcode = this.item.code+"-"+key_1+"-"+key_2;
          copy.idcolor_integration = this.item.colors[key_1][key_2].idcolor_integration ;
          copy.idsize_integration = this.item.colors[key_1][key_2].idsize_integration ;
          arr.push(copy);

        }
      }



    }else{
      let quan_tity ;
      if((<HTMLInputElement>document.getElementById("qtd_stock_"+this.item.iditem)).value==""){
        quan_tity = 0;
      }else{
        valueInput = true;
        quan_tity =  parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+this.item.iditem)).value);
      }

      obj.fullcode = this.item.code;
      obj.quantity = quan_tity;
      this.pval = this.item.priceFinal;

      if(this.item.qtdCart>quan_tity){
        arrsendlitics_desc.push({
          'id': this.item.iditem,
          'name': desc,
          'price': this.pval,
          'brand': brand,
          'category': cat,
          'variant': color,
          'quantity': this.item.qtdCart-quan_tity
         });
         arrsendlitics_descga4.push({
          'item_id': this.item.iditem,
          'item_name': desc,
          'price': this.pval,
          'item_brand': brand,
          'item_category': cat,
          'item_variant': color,
          'quantity': this.item.qtdCart-quan_tity
         });
       }else
       if(this.item.qtdCart<quan_tity){
         arrsendlitics_inc.push({
           'id': this.item.iditem,
           'name': desc,
           'price': this.pval,
           'brand': brand,
           'category': cat,
           'variant': color,
           'quantity': quan_tity-this.item.qtdCart
          });
          arrsendlitics_incga4.push({
            'item_id': this.item.iditem,
            'item_name': desc,
            'price': this.pval,
            'item_brand': brand,
            'item_category': cat,
            'item_variant': color,
            'quantity': quan_tity-this.item.qtdCart
           });
        }
       arr.push(obj);
    }

    if(!valueInput){
      Swal.fire( this.translate.instant('INSERT_QTD_REQUIRED') );
      return 0;
    }

    this.apiService.addCart(arr).subscribe((obj: any)=>{

      if(typeof obj!=="undefined" && obj.code==200){

        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.translate.get( obj.msg ).subscribe(translations => {
        Swal.fire({
          position: 'center',
          customClass: {
            container: 'swal-index',
            confirmButton: 'modalRemoveBackground modalHoverColor',
            cancelButton: 'modalRemoveBackground modalHoverColor'
          },
          icon: 'success',
          width:'35em',
          text: this.translate.instant('keepshopping'),
          title: translations,
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons:true,
          confirmButtonText: this.translate.instant('checkout'),
          cancelButtonText: this.translate.instant('keepbuying')
        }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                 this.router.navigate( [this.localize.translateRoute('/checkout')]  );
              }
            });
        });



        if(this.item && this.item.itemwithattr==1){
          let q_t_d_1= 0;
          for (var [key_1, value_1] of Object.entries(this.item.colors)) {
            for (var [key_2, value_2] of Object.entries(this.item.colors[key_1])) {
                if((<HTMLInputElement>document.getElementById("qtd_stock_"+this.item.iditem+"_"+key_1+"_"+key_2)).value==""){
                  q_t_d_1 = 0;
                }else{
                  q_t_d_1 = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+this.item.iditem+"_"+key_1+"_"+key_2)).value);
                }
                this.item.colors[key_1][key_2].qtdCart = q_t_d_1;
            }
          }

        }else{
          this.item.qtdCart = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+this.item.iditem)).value);
        }


        if(arrsendlitics_inc.length>0){
          arrsendlitics_inc.forEach((val) => {

            this.angulartics2GAEcommerce.ecAddProduct({
                  'id': val.id,
                  'name': val.name,
                  'category': val.category,
                  'brand': val.brand,
                  'variant': val.variant,
                  'price': val.price,
                  'quantity': val.quantity,
                  'position': 0
                });
                this.angulartics2GAEcommerce.ecSetAction("add", {});

  				});


          this.angulartics2.eventTrack.next({
    				action: 'addToCart',
    				properties: {
              label: 'addToCart',
    					currency: "EUR",
    					content_type: 'product_group',
              "event":"addToCart",
              gtmCustom:{
                'ecommerce': {
                  'purchase': undefined,
                  'click': undefined,
                  'checkout': undefined,
                  'detail': undefined,
                  'impressions': undefined,
                  'remove': undefined,
                  'add': {
                    'actionField': {'list': 'addToCart'},    // 'detail'
                    'products': arrsendlitics_inc
                   },
                   'add_to_cart': {
                    'currency': 'EUR', 'value': this.pval,    // 'detail'
                    'items': arrsendlitics_incga4
                  }
                 }
              }
    				}
    			});
        }
        if(arrsendlitics_desc.length>0){
          arrsendlitics_desc.forEach((val) => {

            this.angulartics2GAEcommerce.ecAddProduct({
                  'id': val.id,
                  'name': val.name,
                  'category': val.category,
                  'brand': val.brand,
                  'variant': val.variant,
                  'price': val.price,
                  'quantity': val.quantity,
                  'position': 0
                });
                this.angulartics2GAEcommerce.ecSetAction("remove", {});

  				});


          this.angulartics2.eventTrack.next({
    				action: 'removeFromCart',
    				properties: {
              label: 'removeFromCart',
    					currency: "EUR",
    					content_type: 'product_group',
              "event":"removeFromCart",
              gtmCustom:{
                'ecommerce': {
                  'purchase': undefined,
                  'click': undefined,
                  'checkout': undefined,
                  'detail': undefined,
                  'impressions': undefined,
                  'add': undefined,
                  'remove': {
                    'actionField': {'list': 'removeFromCart'},    // 'detail'
                    'products': arrsendlitics_desc
                   },
                   'remove_from_cart': {
                    'currency': 'EUR', 'value':this.pval,    // 'detail'
                    'items': arrsendlitics_descga4
                   }
                 }
              }
    				}
    			});
        }
        this.pval = undefined;

      }else{
        if(obj.code==302){
           this.router.navigate( [this.localize.translateRoute('/signin')]  );
        }
        if(obj.msg === "NOT_ENOUGH_STOCK"){
          this.stockajust(obj.stock, this.item.iditem, obj.idcolor, obj.idsize);
        }
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  incProd(quantity:any, item:any, color?:any, size?:any){
    let quantityinput ;
    if(!color){
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
    }else{
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
    }
    if(isNaN(quantityinput)){
      quantityinput = 0;
    }
    quantityinput++;
    if(quantityinput>parseInt(quantity)){
      quantityinput = parseInt(quantity);
    }
    if(!color){
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
    }else{
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
    }
  }

  descProd(quantity:any, item:any, color?:any, size?:any){
    let quantityinput ;
    if(!color){
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
    }else{
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
    }
    if(isNaN(quantityinput)){
      quantityinput = 0;
    }
    quantityinput--;
    if(quantityinput<0){
      quantityinput = 0;
    }
    if(!color){
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
    }else{
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
    }
  }


  stockajust(quantity:any, item:any, color?:any, size?:any){
    let quantityinput ;
    if(!color){
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
    }else{
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
    }
    if(isNaN(quantityinput)){
      quantityinput = 0;
    }
    if(quantityinput<0){
      quantityinput = 0;
    }
    if(quantityinput>parseInt(quantity)){
      quantityinput = parseInt(quantity);
    }
    if(!color){
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
    }else{
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
    }
  }


  getPriceRelated(product, op){
    if(product.itemwithattr==1){
      let color = Object.keys(product.colors)[0];
      let size = Object.keys(product.colors[color])[0];
      if(op == 1){
        return product.colors[color][size].priceOriginalTxt
      }
      else{
        return product.colors[color][size].priceFinalTxt;
      }
    }else{
      return product.priceFinalTxt;
    }
  }

  getPriceRecomended(pricetxt: string){
    if(pricetxt){
      return pricetxt.replace(".", ",");
    }
  }

  onSubmit(){

    if(this.submittedReview){return;}
    
    this.submittedReview = true;
    if(this.reviewForm.invalid){ return;}
    var formData = new FormData();
    if(this.purchase_idcolor && this.purchase_idsize){
      formData.append("review[idcolor]", this.purchase_idcolor.toString());
      formData.append("review[idsize]", this.purchase_idsize.toString());
    }
    Object.keys(this.reviewForm.controls).forEach(key => {
      if(this.reviewForm.controls[key].value){
        formData.append("review["+key+"]", this.reviewForm.controls[key].value.toString());
      }else{
        formData.append("review["+key+"]", "");
      }
    });

    if(this.filelist && this.filelist.length > 0){
      for (let index = 0; index < this.filelist.length; index++) {
        formData.append("image["+index+"]", this.filelist[index]);
      }
    }

    this.apiService.reviewItem(formData, this.iditem, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.hidecomment = true;
        this.reviewForm.reset();
        this.sharedpoints.send();
        this.images_64 = []; this.filelist = [];
        this.translate.get( obj.msg ).subscribe(translations => {
          Swal.fire({
            position: 'top-end',
            customClass: {
              container: 'swal-index'
            },
            icon: 'success',
            title: translations,
            showConfirmButton: false,
            timer: 2000
          });
        });
      }
      else{
        this.translate.get(obj.msg).subscribe(translations => {Swal.fire(translations);});
      }
    })
  }

  getProductReviews(lang:any, all:string){
    this.loadAll = all;
    this.apiService.getProductReviews(lang, this.iditem, all).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.reviews = obj.info;  
        if(this.reviews.length <= 6){ this.loadAll= '1'; }
        this.totalreviews = Number(obj.totalreviews);
        if(obj.average){
          this.average_rating = parseFloat(obj.average);
        }
        if(obj.canreview == 0){
          this.hidecomment = true; 
        }
        else{
          this.purchase_idcolor = obj.purchase_idcolor;
          this.purchase_idsize = obj.purchase_idsize;
        }
      }
      else{
        this.translate.get(obj.msg).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    })
  }

  addToFavorite(){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });


    if( (this.item && this.item.itemwithattr==0 && this.item.wishlist==0) || (this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==0) ){
      this.apiService.addwishlist(this.iditem, this.idcolor).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(this.item.itemwithattr==0){
            this.item.wishlist=1;
          }else{
            this.item.colors[this.idcolor][this.idsize].wishlist=1;
          }
          let a = this.translate.instant( obj.msg );
          Toast.fire({
            icon: 'success',
            html:'<h5>'+a+'</h5>'
          });
          this.wishlistService.send();
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }else{
      this.apiService.removewishlist(this.iditem, this.idcolor).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(this.item.itemwithattr==0){
            this.item.wishlist=0;
          }else{
            this.item.colors[this.idcolor][this.idsize].wishlist=0;
          }
          let a = this.translate.instant( obj.msg );
          Toast.fire({
            icon: 'success',
            html:'<h5>'+a+'</h5>'
          });
          this.wishlistService.send();
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }
  }

  uploadFile(event: Event){
    if(this.filelist.length >= 8){ this.translate.get('limit_images_upload').subscribe(translations => { Swal.fire(translations);}); }
    const element = event.currentTarget as HTMLInputElement;
    let fileList: any | null = element.files;
    if (fileList) {
      for (let index = 0; index < fileList.length; index++) {
        if(fileList[index].type == 'image/png' || fileList[index].type == 'image/jpg' || fileList[index].type == 'image/jpeg'){
          var reader = new FileReader();
          reader.onload =this._handleReaderLoaded.bind(this);
          reader.readAsBinaryString(fileList[index]);
          this.filelist.push(fileList[index]);
        }
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.images_64.push(btoa(binaryString))
  }

  deleteFile(index:any){
    this.images_64.splice(index,1);
    this.filelist.splice(index,1);
  }

  openImageReview(index: number, images:any){
    this.showimageReviews = true;
    let arr = [];
    images.forEach(element => {
      let obj:any = {};
      obj.image = this.apiService.imagesDir + element.photo
      arr.push(obj);
    });

    this.imageReviews = arr;
    this.imageReviewIndex = index;
  }
  closeImageReviews(){
    this.showimageReviews = false;
    this.imageReviewIndex = -1;
  }

  getReviewById(id:any){
    this.apiService.getProductReview(id).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.reviewDetail = obj.info;
        this.openReviewModal();
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  openReviewModal(){
    this.modalReviewRef = this.modalService.open(this.ModalReview, {
        size: 'md',
        windowClass: 'modal-md'  ,
        centered: true,
        animation: true,
        hideCloseButton: false,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: 'modal-backdrop'
      });
    this.modalReviewRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
  }

  getPoints(){
    this.apiService.getPoints("3,4,5,7,8").subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.ac_points = obj.info;
        if(obj.info && obj.info.length > 0){
          obj.info.forEach(element => {
            if(element.idpointtype == '7' || element.idpointtype == '8'){
              this.showsharelink = true;
            }
          });
        }
        else if(obj.info){
          if(obj.info.idpointtype == '7' || obj.info.idpointtype == '8'){
            this.showsharelink = true;
          }
        }
      }
    })
  }

  generateLink(){
    this.apiService.generateLinkCode(this.iditem).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.linkCode = window.location.href + '?sharelink='+obj.info;
        this.copylink(this.linkCode);
      }
      else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    })
  }

  copylink(val:string){

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    $('.modal-copy').slideToggle("fast");
    setTimeout(() => {$('.modal-copy').slideToggle("fast");},2000);
  }

  sendClickEvent(linkCode){
    this.apiService.sendClickEvent(linkCode).subscribe((obj: any)=>{})
  }

  scroll(element) {
    element.scrollIntoView({behavior: 'smooth'})
  }

  getProductAccPoints(){
    this.apiService.getProductAccPoints(this.iditem, this.idcolor, this.idsize).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.salesPoints = obj.points;
      }
      else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    })
  }

  createStructuredData(id:any, name:string, url:string, description: string, images:any[], price:any, brand: string , color: string, aggregateRating?: string, reviewCount?: number, review?:any){

    if(this.scriptProduct){
      this._renderer2.removeChild(this._document.body, this.scriptProduct);
    }
 
    brand = (brand) ? brand : 'ModaServerPro';
    let country = this.translate.currentLang.toUpperCase();

    let images_arr:any = [];
    images.forEach((element:any) => {
      let imgpath = '"'+this.apiService.imagesDir + element.photo + '"';
      images_arr.push(imgpath);
    });

    this.scriptProduct = this._renderer2.createElement('script');
    this.scriptProduct.type = `application/ld+json`;
    this.scriptProduct.text = `
        {
            "@context": "https://schema.org",
            "@type":"Product",
            "@id":"`+id+`",
            "sku": "`+this.item.code+`",
            "name":"`+name+`",
            "url":"`+url+`",
            "color":"`+color+`",
            "description":"`+description+`",
            "image":[`+images_arr+`],
            "brand": {
              "@type": "Brand",
              "name": "`+brand+`"
            },`;
            if(review){
              this.scriptProduct.text += `
              "review": {
                  "@type": "Review",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": `+review.rating+`,
                    "bestRating": 5
                  },
                  "author": {
                    "@type": "Person",
                    "name": "`+review.name+`"
                  }
                },
              `;
             }
            if(aggregateRating && aggregateRating != '0.00'){
              this.scriptProduct.text += `
              "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": `+aggregateRating+`,
                  "reviewCount": `+reviewCount+`
                },
              `;
              }
             this.scriptProduct.text +=`
             "offers": [
              {
                "@type":"Offer",
                "price":"`+price+`",
                "itemCondition": "NewCondition",
                "priceSpecification": {
                  "price":"`+price+`",
                  "priceCurrency":"EUR",
                  "valueAddedTaxIncluded":"true"
                },
                "hasMerchantReturnPolicy": {
                  "@type": "MerchantReturnPolicy",
                  "applicableCountry": "`+country+`",
                  "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
                  "merchantReturnDays": 15,
                  "returnMethod": "https://schema.org/ReturnByMail",
                  "returnFees": "https://schema.org/FreeReturn"
                },
                "shippingDetails":{
                  "@type":"OfferShippingDetails",
                  "shippingRate":{
                     "@type":"MonetaryAmount",
                     "value":6.90,
                     "currency":"EUR"
                  },
                  "shippingDestination":{
                     "@type":"DefinedRegion",
                     "addressCountry":"`+country+`"
                  },
                  "deliveryTime":{
                     "@type":"ShippingDeliveryTime", 
                     "handlingTime":{
                        "@type":"QuantitativeValue",
                        "minValue":0,
                        "maxValue":1,
                        "unitCode":"DAY"
                     },
                     "transitTime":{
                        "@type":"QuantitativeValue",
                        "minValue":1,
                        "maxValue":3,
                        "unitCode":"DAY"
                     },
                     "businessDays": {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [ "https://schema.org/Monday", "https://schema.org/Tuesday", "https://schema.org/Wednesday", "https://schema.org/Thursday","https://schema.org/Friday" ]
                    }
                  }
               },        
              "priceCurrency":"EUR",`;
                if(!this.item.notstock){
                  this.scriptProduct.text += `
                "availability":"InStock",`;
                }
                else{
                  this.scriptProduct.text += `
                "availability":"OutOfStock",`;
                }
                this.scriptProduct.text +=`
                "url":"`+url+`",
                "seller":{
                  "@type":"Organization",
                  "name":"ModaServerPro",
                  "url":"`+this.apiService.PHP_API_URL_DEFAULT+`"
                }`
               this.scriptProduct.text += `
              }
            
          ]`;

          if(this.item.video && this.item.videothunb && this.item.videothunb.thumbnail_small){
            const datevideo_string = new Date(this.item.updated_at);
            const datevideo_format = new Date(datevideo_string.setMinutes(datevideo_string.getMinutes() - datevideo_string.getTimezoneOffset()));
            this.scriptProduct.text += `
            ,"videos":[
              {
                 "@type":"VideoObject",
                 "name":"`+name+`",
                 "uploadDate":"`+datevideo_format+`",
                 "thumbnailUrl":"`+this.item.videothunb.thumbnail_small+`",
                 "description":"`+description+`",
                 "contentUrl":"`+this.item.video+`"
              }
           ]`;
          }
        this.scriptProduct.text += `}`;
    this._renderer2.appendChild(this._document.body, this.scriptProduct);
  }
}
